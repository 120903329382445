import { BASE_URL, LB_BASE_URL } from "../app/constants";
import { getAuthToken } from "../utils/AuthToken";
import { getRandomInt } from "../utils/UtilityFunctions";
import axios from 'axios';
import { BASE_URL_GO } from '../app/constants';
import { handleSuccess, handleError } from './responseHandler';


export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(true)}`,
  }
});

export const getConnectorConfig = { crossdomain: true };

export function getLoadBalancedApiBaseUrl() {
  if (!Array.isArray(LB_BASE_URL) || LB_BASE_URL.length === 0) {
    return BASE_URL;
  }

  let urls = [];
  LB_BASE_URL.forEach((item) => {
    for (let i = 0; i < item.wt; i++) urls.push(item.url);
  });

  const idx = getRandomInt(1, urls.length);
  return urls[idx - 1];
}


export const getConfigForDeleteHttpVerb = function (data) {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken(true)}`,
    }, data
  }
};

export async function gocallApi(type = '', geturl = '', data = null, needCompleteResult = false) {
  
  if (geturl === '' || type === '') return;
  let url = `${BASE_URL_GO}${geturl}`;
  let result = {}
  try {
    switch (type.toLocaleLowerCase()) {
      case 'get':
        result = await axios.get(url, getConfig());
        break;
      case 'put':
        result = await axios.put(url, data, getConfig());
        break;
      case 'post':
        result = await axios.post(url, data, getConfig());
        break;
      case 'delete':
        result = await axios.delete(url, getConfigForDeleteHttpVerb(data));
        break;
      default:
    }

    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    if (needCompleteResult) {
      return handleSuccess(result);
    } else {
      return handleSuccess(result.data);
    }
  }
  catch (err) {
    throw handleError(err);
  }

}
