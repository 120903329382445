import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './TwoFactorAuthentication.scss';

const TwoFactorAuthVerify = (props) => {

    const { show, handleClose, handleSuccess, setAuthOTP } = props;

    return (

        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gst_gauth_verifycode_module modal"
            >

                <Modal.Header closeButton className="gst_gauth_verifycode_header">
                    <h4 className="modal-title">Verify Code Received</h4>
                </Modal.Header>

                <Modal.Body className='pt-2 pb-2 px-3'>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="gst_gauth_verifycode_textfs">
                                Enter the verification code generated by your authenticator app
                            </p>
                            <div className="form-group gst_gauth_verifycode_inputgrp">
                                <input
                                    type="text"
                                    className="form-control gst_gauth_verifycode_inputbox"
                                    placeholder="Enter Code"
                                    maxLength={6}
                                    onChange={(e) => setAuthOTP(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-xl-12 text-center pt-3">
                            <button className="btn btn-default gst_factorauth_scanbar_verifybtn mr-2 mb-1" onClick={() => handleSuccess()}>
                                Verify
                            </button>
                            <button className="btn btn-default gst_factorauth_scanbar_verifybtn" onClick={() => handleClose(true)}>
                                Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default TwoFactorAuthVerify