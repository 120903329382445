import React, { useState } from "react";
import { SYNC_PORTAL_DATA_MODE } from "../../app/constants";
import SyncData from "./SyncData";
import ConnectorAdapter from "../header/ConnectorAdapter";
import SyncDataByConnector from "./SyncDataByConnector";
import { syncStatus } from "../../api/2adataAPI";


const SyncPortalData = (props) => {

    const {
        company_id,
        year,
        show,
        handleClose,
        refreshSupplierList,
        syncStatusFailureMsg,
        syncStatusRemindLater,
        companyGSTNumber,
        setError,
        setSuccess,
        syncWithParentFn,
        setShowSyncVerify,
        setLoading
    } = props;

    const [port, setPort] = useState(0);
    const [startConnector, setStartConnector] = useState(true);
    const [showSyncVerifyInner, setShowSyncVerifyInner] = useState(false);

    const getSyncStatus = (company_id) => { 
        async function fetchEverything() {
          async function fetchSyncStatus() {
            await syncStatus(company_id);
          }
          try {
            await Promise.all([
              fetchSyncStatus(),
            ]);
            // setError(null);
          } catch (err) {
            if(setError)setError(err.message);
            // eslint-disable-next-line no-empty
          } finally {
            if(setLoading) setLoading(false)
          }
        }
        if(setLoading) setLoading(true)
        fetchEverything();
      };


    if (SYNC_PORTAL_DATA_MODE === 1) {

        return (
            <SyncData
                show={show}
                handleClose={() => {handleClose()}}
                company_id={company_id}
                year={year}
                refreshSupplierList={(msg) => {
                    if(!syncWithParentFn){
                        getSyncStatus(company_id);
                        if(setSuccess) setSuccess(msg)
                    }else{
                        refreshSupplierList(msg)
                    }                    
                }}
                syncStatusFailureMsg={syncStatusFailureMsg}
                syncStatusRemindLater={syncStatusRemindLater}
                companyGSTNumber={companyGSTNumber}
            />
        )
    }

    if (SYNC_PORTAL_DATA_MODE === 2) {
        return (
            <>
                {
                    port === 0
                        ?
                        <ConnectorAdapter
                            show={startConnector}
                            handleClose={(port=0) => {
                                setStartConnector(false);
                                if(setShowSyncVerify && port === 0)setShowSyncVerify(false)
                            }}
                            setPort={setPort}
                            handleProceed={() => { 
                                setShowSyncVerifyInner(true) 
                            }}
                        />
                        : null
                }

                {
                    (showSyncVerifyInner && port > 0)
                        ?
                        <SyncDataByConnector
                            show={show}
                            handleClose={() => handleClose()}
                            company_id={company_id}
                            year={year}
                            refreshSupplierList={(msg) => 
                               {
                                if(!syncWithParentFn){
                                    getSyncStatus(company_id);
                                    if(setSuccess) setSuccess(msg)
                                }else{
                                    refreshSupplierList(msg)
                                } 
                               }
                            }
                            syncStatusFailureMsg={syncStatusFailureMsg}
                            syncStatusRemindLater={syncStatusRemindLater}
                            companyGSTNumber={companyGSTNumber}
                            port={port}
                            setError={() => setError ? setError() : null}
                        />
                        : null
                }

            </>

        )

    } 

}

export default SyncPortalData;