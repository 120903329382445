import React, { useEffect, useState } from 'react';
import './CredentialsPopup.scss';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eWayBillStartSyncUsingConnector, getCaptchaForEWBFromConnector, initiateVerifyEWayPortalLogin } from '../../api/localConnectorAPI';
import Spinner from "react-bootstrap/Spinner";
import checkIcon from "../../images/billcheckicon.png";
import closeIcon from "../../images/billclose.png";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { eWayBillStartSync, eWayBillSyncLoginRequest } from '../../api/serverConnectorAPI';
import ShowAlert from '../../components/ShowAlert';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';


const EWayCredentialsPopup = (props) => {

    const { port, ewbUserName, ewbUserPassword, company_id, isEwbVerified, getEWayBillSyncStatus, handleClose, gspCredsData, setGspErrObj, startGspSync } = props;

    const [loading, setLoading] = useState(false);
    const [credsData, setCredsData] = useState({ username: ewbUserName, password: ewbUserPassword });
    const [errObj, setErrObj] = useState({});
    const [captchaLoading, setCaptchaLoading] = useState(false)
    const [error, setError] = useState(null);
    const [captchaImg, setCaptchaImg] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaRef, setCaptchaRef] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [otp, setOtp] = useState("");


    const getCaptchaFunc = async () => {

        setCaptcha('');
        setCaptchaLoading(true);
        try {
            const result = await getCaptchaForEWBFromConnector(port);
            if (result?.data) {
                setCaptchaImg(result?.data?.captcha_image ?? "");
                setCaptchaRef(result?.data?.captcha_ref ?? "")
            }
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
            setCaptchaLoading(false);
        }
    };

    const handleSubmit = () => {

        const errorObject = {};
        const gspErrObj = {};

        if (gspCredsData?.username?.trim()?.length === 0 || !gspCredsData?.username) {
            gspErrObj.username = "Please enter the username";
        }
        if (gspCredsData?.password?.trim()?.length === 0 || !gspCredsData?.password) {
            gspErrObj.password = "Please enter the password";
        }

        if (credsData?.username?.trim()?.length === 0 || !credsData?.username) {
            errorObject.username = "Please enter the username";
        }
        if (credsData?.password?.trim()?.length === 0 || !credsData?.password) {
            errorObject.password = "Please enter the password";
        }
        if (captcha.trim()?.length === 0) {
            errorObject.captcha = "Please enter the captcha";
        }

        if (Object.keys(errorObject).length > 0 && Object.keys(gspErrObj).length === 0) {
            startGspSync(company_id, gspCredsData?.username ?? "", gspCredsData?.password ?? "");
        } else if (Object.keys(gspErrObj).length > 0 && Object.keys(errorObject).length === 0) {
            requestLoginForSync();
        } else if (Object.keys(errorObject).length === 0 && Object.keys(gspErrObj).length === 0) {
            startGspSync(company_id, gspCredsData?.username ?? "", gspCredsData?.password ?? "");
            requestLoginForSync("stopAtLogin");
        } else {
            setErrObj(errorObject);
            setGspErrObj(gspErrObj);
            return;
        }

    };

    const requestLoginForSync = async (checkKey) => {
        setLoading(true);

        const postData = {
            company_id: company_id,
            captcha_ref: captchaRef,
            captcha_text: captcha,
            ewb_username: credsData?.username ?? "",
            ewb_password: credsData?.password ?? ""
        }

        if (otp?.trim()?.length > 0) {
            postData.otp = otp;
        }

        try {
            const result = await eWayBillSyncLoginRequest(postData);
            const responseData = result.data ?? null;
            const responseHeader = result.headers ?? null;
            if (Number(responseData.status === 1)) {
                const connectorRequest = {
                    data: responseData?.data,
                };

                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                try {
                    const response = await initiateVerifyEWayPortalLogin(port, connectorRequest, extraHeader);
                    if (response?.data) {
                        if (checkKey === "stopAtLogin") {
                            return;
                        }
                        startSyncUsingConnector(response?.data?.captcha_ref);
                    }
                } catch (err) {
                    setError(CONNECTOR_ERRORS_MAPPING[err?.message] ? CONNECTOR_ERRORS_MAPPING[err?.message] : err?.message ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
                    setCaptcha("");
                    getCaptchaFunc();
                    setTimeout(() => {
                        handleClose()
                    }, 4000);                    
                }
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const startSyncUsingConnector = async (captcha_ref) => {
        setLoading(true);

        const postData = {
            company_id: company_id,
            captcha_ref: captcha_ref,
            captcha_text: captcha,
        };

        try {
            const result = await eWayBillStartSync(postData);
            const responseData = result.data ?? null;
            const responseHeader = result.headers ?? null;
            if (Number(responseData.status === 1)) {
                const connectorRequest = {
                    data: responseData?.data,
                };

                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                try {
                    const response = await eWayBillStartSyncUsingConnector(port, connectorRequest, extraHeader);
                    if (Number(response?.status) === 1) {
                        getEWayBillSyncStatus(company_id);
                        handleClose();

                    };
                } catch (err) {
                    setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
                    setCaptcha("");
                    getCaptchaFunc();
                    setTimeout(() => {
                        handleClose()
                    }, 4000);
                }
            }

        } catch (err) {
            setError(err.message);            
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCaptchaFunc();
        //eslint-disable-next-line
    }, []);


    return (

        <>

            {loading ? <LoadingIndicator /> : null}

            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
            />

            <fieldset className="gsteway_GSPCredentials_fs gsteway_GSPCredentials_fsborderblue mt-0">
                <legend className="gsteway_GSPCredentials_leg mb-0">
                    <div className="gsteway_GSPCredentials_inndev">
                        <div className="gsteway_GSPCredentials_leftside mb-0">
                            <h4>Add Login details of ewaybillgst.gov.in</h4>
                        </div>
                        <div className="gsteway_GSPCredentials_innerhieghtdiv"></div>
                        <div className="gsteway_GSPCredentials_rightsidediv">
                            <span className="gsteway_GSPCredentials_billiconbox">
                                <img
                                    src={isEwbVerified ? checkIcon : closeIcon}
                                    width="22" alt='details'
                                />
                            </span>
                        </div>
                        <div className="gsteway_GSPCredentials_middlebor gsteway_GSPCredentials_middleborderblue"></div>
                    </div>
                </legend>

                <div className="row">
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <label for="" className="gsteway_Addinfo_bill_label">Username<span className="red_text">*</span></label>
                        <input
                            type="text"
                            className="form-control gsteway_Addinfo_bill_grpinput"
                            placeholder="Enter Username"
                            value={credsData?.username ?? ""}
                            onChange={(e) => setCredsData({ ...credsData, username: e.target.value })}
                        />
                        <span className='red_text'>{errObj?.username ?? ""}</span>
                    </div>
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <label for="" className="gsteway_Addinfo_bill_label">Password<span className="red_text">*</span></label>

                        {showPassword ?
                            <div className="input-group">
                                <input
                                    type="input"
                                    className="form-control gsteway_Addinfo_bill_passinput"
                                    placeholder="Enter Password"
                                    value={credsData?.password ?? ""}
                                    onChange={(e) => setCredsData({ ...credsData, password: e.target.value })}
                                />
                                <div className="input-group-prepend gsteway_Addinfo_bill_prependbox_eyeicon" onClick={() => setShowPassword(false)}>
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                </div>
                            </div>
                            :
                            <div className="input-group">
                                <input
                                    type="password"
                                    className="form-control gsteway_Addinfo_bill_passinput"
                                    placeholder="Enter Password"
                                    value={credsData?.password ?? ""}
                                    onChange={(e) => setCredsData({ ...credsData, password: e.target.value })}
                                />
                                <div className="input-group-prepend gsteway_Addinfo_bill_prependbox_eyeicon" onClick={() => setShowPassword(true)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </div>
                            </div>
                        }
                        <div>
                            <span className='red_text'>{errObj?.password ?? ""}</span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        {!captchaLoading ?
                            (
                                <img
                                    src={`data:image/png;base64, ${captchaImg}`}
                                    alt='captcha' width="100%"
                                />
                            ) :
                            (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )
                        }
                    </div>
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6 mt-2">
                        <input
                            type="text"
                            className="form-control gsteway_Addinfo_bill_grpinput"
                            placeholder="Enter OTP in case of 2Way auth"
                            value={otp ?? ""}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </div>

                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <input
                            type="text"
                            className="form-control gsteway_Addinfo_bill_grpinput"
                            placeholder="Enter Captcha"
                            value={captcha ?? ""}
                            onChange={(e) => setCaptcha(e.target.value)}
                        />
                        <span className='red_text'>{errObj?.captcha ?? ""}</span>
                    </div>



                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <button className="gsteway_Addinfo_bill_submitbtn mt-1">
                            Resend OTP
                        </button>
                    </div>

                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-12 pt-2 text-center">
                        <button className="gsteway_Addinfo_bill_submitbtn" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>

            </fieldset>

        </>
    )
}

export default EWayCredentialsPopup