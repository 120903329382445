import axios from 'axios';
import { BASE_URL, BASE_URL_GO, CONNECTOR_VERSION_URL } from '../app/constants';
import { handleSuccess, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';

export async function getLatestConnectorVersionInfo() {
  if (!CONNECTOR_VERSION_URL) {
    return {}
  }

  try {
    const result = await axios.get(CONNECTOR_VERSION_URL);
    if (Number(result.status) !== 200) {
      return {};
    }
    return result.data;
  } catch (err) {
    return {};
  }
}


export async function getGstPortalLoginCredentials(companyId) {
  const url = `${BASE_URL}/api/v1/connector/gst-portal/login-creds?company_id=${companyId}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result);
  } catch (error) {
    throw handleErrorWithCode(error);
  }
}

export async function startConnectorGstr1Filing(data) {
  const url = `${BASE_URL}/api/v1/connector/gstr1/filing/start`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function startConnectorGstr1AFiling(data) {
  const url = `${BASE_URL}/api/v1/connector/gstr1a/filing/start`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function startConnectorGstr3BFiling(data) {
  const url = `${BASE_URL}/api/v1/connector/gstr3b/filing/start`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function startConnectorGstr9Filing(data) {
  const url = `${BASE_URL}/api/v1/connector/gstr9/filing/start`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function startConnectorGstr9CFiling(data) {
  const url = `${BASE_URL}/api/v1/connector/gstr9c/filing/start`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function startDRCFiling(data) {
  const url = `${BASE_URL}/api/v1/connector/drc03/annual/start`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function fetchCompanyListToConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/tally/fetch-company-list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function storeCompanyListFromConnector(data, extraHeaders) {
  const url = `${BASE_URL_GO}/api/v1/cusu/tally/store-company-list`;
  const configHeaders = getConfig().headers
  try {
    const result = await axios.post(url, data, { headers: { ...configHeaders, ...extraHeaders } });
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}



export async function syncCompanyToConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/tally/sync-company`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function publicPageFetchCompanyToConnector(token, data) {
  const url = `${BASE_URL_GO}/api/v1/pub-auth/usuc/tally/fetch-company-list`;
  try {
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const result = await axios.post(url, data, headers);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function publicPagetoreCompanyListFromConnector(data, extraHeaders, token) {
  const url = `${BASE_URL_GO}/api/v1/pub-auth/cusu/tally/store-company-list`;
  const headers = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...extraHeaders
    }
  }
  try {
    const result = await axios.post(url, data, headers);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function publicPagetallyMapCompany(data, token) {
  const url = `${BASE_URL_GO}/api/v1/pub-auth/tally/map-company`;
  const headers = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const result = await axios.post(url, data, headers);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function publicTallyMappingSyncStatus(token) {
  const url = `${BASE_URL_GO}/api/v1/pub-auth/tally/mapping-sync-status`;
  const headers = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const result = await axios.get(url, headers);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function publicSyncCompanyToConnector(token) {
  const url = `${BASE_URL_GO}/api/v1/pub-auth/usuc/tally/sync-company`;
  const headers = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const result = await axios.post(url, {}, headers);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    const response = { data: result.data, headers: result.headers }
    return handleSuccess(response);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function serverLoginRequestForConnectorLogin(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/gstportal/login-request`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }    
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function eWayBillSyncLoginRequest(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/ewbportal/login-request`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }    
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function eWayBillStartSync(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/ewbportal/start-sync`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }    
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};