import React, { useEffect, useState } from 'react';
import { getEwayCredentialsByID, startEwayGspSync } from '../../api/EWayBillsAPI';
import ShowAlert from '../../components/ShowAlert';
import GSPCredentialsPopup from './GSPCredentialsPopup';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';


const SyncData = (props) => {

  const { company_id, getEWayBillSyncStatus, eWaySyncStatusFailureMsg, closePopup } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [gspErr, setGspErr] = useState(false);

  const [isGspVerified, setIsGspVerified] = useState(null);
  const [gspUserName, setGspUserName] = useState(null);
  const [gspUserPassword, setGspUserPassword] = useState(null);

  const [isEwbVerified, setIsEwbVerified] = useState(null);
  const [ewbUserName, setEwbUserName] = useState(null);
  const [ewbUserPassword, setEwbUserPassword] = useState(null);

  const [showGspCredsPopup, setShowGspCredsPopup] = useState(false);
  const [showEwbCredPopup, setShowEwbCredPopup] = useState(false);


  const getEwaySyncCredentials = async () => {
    setLoading(true);
    try {
      const result = await getEwayCredentialsByID(company_id);
      // const result = {
      //   data: {
      //     ewb_isverified: true,
      //     ewb_password: "Mercy@12345",
      //     ewb_user_name: "Mercygrace_123",
      //     gsp_isverified: true,
      //     gsp_password: "Mercygrace@456",
      //     gsp_username: "Mercygrace_API_EWB"
      //   }
      // }
      // const result = {
      //   data: null
      // }
      if (result?.data) {

        setIsGspVerified(result?.data?.gsp_isverified ?? null);
        setGspUserName(result?.data?.gsp_username ?? "");
        setGspUserPassword(result?.data?.gsp_password ?? "");

        setIsEwbVerified(result?.data?.ewb_isverified ?? null);
        setEwbUserName(result?.data?.ewb_user_name ?? "");
        setEwbUserPassword(result?.data?.ewb_password ?? "");

        if ((result?.data?.gsp_isverified && result?.data?.ewb_isverified) || result?.data?.gsp_isverified) {
          // either both GSP & EWB creds present or only GSP creds present
          startGspSync(company_id, result?.data?.gsp_username, result?.data?.gsp_password)
        } else {
          // only EWB creds present
          setShowGspCredsPopup(true);
          setShowEwbCredPopup(true);
        }

      } else {
        setShowGspCredsPopup(true);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const startGspSync = async (companyId, gspUsername, gspPassword) => {
    setLoading(true);

    const postData = {
      company_id: companyId,
      gsp_username: gspUsername,
      gsp_password: gspPassword,
    }

    try {
      const result = await startEwayGspSync(postData);
      if (result?.data) {
        getEWayBillSyncStatus(companyId);
        setTimeout(() => {
          setShowGspCredsPopup(false)
        }, 3000);
      }
    } catch (err) {
      setGspErr(err.message);
      setTimeout(() => {
        setShowGspCredsPopup(false);
        closePopup();
      }, 3000);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getEwaySyncCredentials();

    if (eWaySyncStatusFailureMsg !== "") {
      setError(eWaySyncStatusFailureMsg);
    }
    //eslint-disable-next-line
  }, [company_id]);



  return (
    <>

      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error || gspErr}
        onClose={() => { setError(null); setGspErr(null); }}
      />

      {showGspCredsPopup &&
        <GSPCredentialsPopup
          show={showGspCredsPopup}
          handleClose={() => {
            setShowGspCredsPopup(false);
            closePopup();
          }}
          company_id={company_id}
          startGspSync={(companyId, username, password) =>
            startGspSync(companyId, username, password)
          }
          gspUserName={gspUserName}
          gspUserPassword={gspUserPassword}
          ewbUserName={ewbUserName}
          ewbUserPassword={ewbUserPassword}
          isEwbVerified={isEwbVerified}
          getEWayBillSyncStatus={getEWayBillSyncStatus}
          isGspVerified={isGspVerified}
          showEwbCredPopup={showEwbCredPopup}
          gspErr={gspErr}
          setGspErr={setGspErr}
        />
      }

    </>
  )

};

export default SyncData;

