import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import './CredentialsPopup.scss';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConnectorAdapter from '../header/ConnectorAdapter';
import EWayCredentialsPopup from './EWayCredentialsPopup';
import checkIcon from "../../images/billcheckicon.png";
import closeIcon from "../../images/billclose.png";
import ShowAlert from '../../components/ShowAlert';


const GSPCredentialsPopup = (props) => {

    const { show, handleClose, company_id, startGspSync, gspUserName, gspUserPassword, ewbUserName,
        ewbUserPassword, isEwbVerified, getEWayBillSyncStatus, isGspVerified, showEwbCredPopup, gspErr, setGspErr } = props;

    const [gspCredsData, setGspCredsData] = useState({ username: gspUserName, password: gspUserPassword });
    const [errObj, setErrObj] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showEwbCredsPopup, setShowEwbCredsPopup] = useState(false);
    const [startConnector, setStartConnector] = useState(false);
    const [port, setPort] = useState(0);


    const onSubmit = () => {

        const errorObj = {};

        if (gspCredsData?.username?.trim()?.length === 0) {
            errorObj.username = "Please enter a username";
        }
        if (gspCredsData?.password?.trim()?.length === 0) {
            errorObj.password = "Please enter a password";
        }
        setErrObj(errorObj);

        if (Object.keys(errorObj).length > 0) return;

        startGspSync(company_id, gspCredsData?.username ?? "", gspCredsData?.password ?? "");

    };


    useEffect(() => {
        if (showEwbCredPopup) {
            setStartConnector(true);
        }
    }, [showEwbCredPopup]);


    return (
        <>

            {startConnector &&
                <ConnectorAdapter
                    show={startConnector}
                    handleClose={(port = 0) => {
                        setStartConnector(false);
                        if (port === 0) handleClose();
                    }}
                    setPort={setPort}
                    handleProceed={(port) => {
                        setPort(port);
                        setShowEwbCredsPopup(true);
                    }}
                />
            }


            <Modal
                show={show}
                onHide={() => {
                    handleClose();
                    setShowEwbCredsPopup(false);
                }}
                backdrop="static"
                keyboard={false}
                className="gsteway_Addinfo_bill_module modal"
            >

                <ShowAlert
                    error={gspErr}
                    onClose={() => setGspErr(null)}
                />

                <Modal.Header closeButton className="gsteway_Addinfo_bill_header">
                    <h4 className="modal-title gsteway_Addinfo_bill_title">Add Info for Eway Bill Module</h4>
                </Modal.Header>
                <Modal.Body className="pt-1 pb-1 px-2">
                    <div className="row">
                        <div className="col-md-12">
                            <fieldset className="gsteway_GSPCredentials_fs">


                                <legend className="gsteway_GSPCredentials_leg mb-0">
                                    <div className="gsteway_GSPCredentials_inndev">
                                        <div className="gsteway_GSPCredentials_leftside mb-0">
                                            <h4>E-Way Bill GSP Credentials</h4>
                                        </div>
                                        <div className="gsteway_GSPCredentials_innerhieghtdiv"></div>
                                        <div className="gsteway_GSPCredentials_rightsidediv">
                                            <span className="gsteway_GSPCredentials_billiconbox">
                                                <img
                                                    src={isGspVerified ? checkIcon : closeIcon}
                                                    alt='check' width="22"
                                                />
                                            </span>
                                        </div>
                                        <div className="gsteway_GSPCredentials_middlebor"></div>
                                    </div>
                                </legend>

                                <div className="row">
                                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                                        <label for="" className="gsteway_Addinfo_bill_label">Username<span className="red_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control gsteway_Addinfo_bill_grpinput"
                                            placeholder="Enter Username"
                                            value={gspCredsData?.username ?? ""}
                                            onChange={(e) => setGspCredsData({ ...gspCredsData, username: e.target.value })}
                                        />
                                        <span className='red_text'>{errObj?.username ?? ""}</span>
                                    </div>
                                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                                        <label for="" className="gsteway_Addinfo_bill_label">Password<span className="red_text">*</span></label>

                                        {showPassword ?
                                            <div className="input-group">
                                                <input
                                                    type="input"
                                                    className="form-control gsteway_Addinfo_bill_passinput"
                                                    placeholder="Enter Password"
                                                    value={gspCredsData?.password ?? ""}
                                                    onChange={(e) => setGspCredsData({ ...gspCredsData, password: e.target.value })}
                                                />
                                                <div className="input-group-prepend gsteway_Addinfo_bill_prependbox_eyeicon" onClick={() => setShowPassword(false)}>
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                </div>
                                            </div>
                                            :
                                            <div className="input-group">
                                                <input
                                                    type="password"
                                                    className="form-control gsteway_Addinfo_bill_passinput"
                                                    placeholder="Enter Password"
                                                    value={gspCredsData?.password ?? ""}
                                                    onChange={(e) => setGspCredsData({ ...gspCredsData, password: e.target.value })}
                                                />
                                                <div className="input-group-prepend gsteway_Addinfo_bill_prependbox_eyeicon" onClick={() => setShowPassword(true)}>
                                                    <FontAwesomeIcon icon={faEye} />
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            <span className='red_text'>{errObj?.password ?? ""}</span>
                                        </div>
                                    </div>

                                    {!showEwbCredsPopup &&
                                        <div className="form-group gsteway_Addinfo_bill_grp col-sm-12 text-center pt-2 mb-0">
                                            <button className="gsteway_Addinfo_bill_submitbtn" onClick={onSubmit}>
                                                Submit
                                            </button>
                                        </div>
                                    }
                                </div>

                            </fieldset>

                            {showEwbCredsPopup &&
                                <EWayCredentialsPopup
                                    port={port}
                                    ewbUserName={ewbUserName}
                                    ewbUserPassword={ewbUserPassword}
                                    company_id={company_id}
                                    isEwbVerified={isEwbVerified}
                                    getEWayBillSyncStatus={getEWayBillSyncStatus}
                                    handleClose={handleClose}
                                    gspCredsData={gspCredsData}
                                    setGspErrObj={setErrObj}
                                    startGspSync={startGspSync}
                                />
                            }

                        </div>

                        {!showEwbCredsPopup &&
                            <div className="col-md-12 text-center pt-2 pb-2">
                                <span className="gsteway_Addinfo_bill_gpsAPI_textfs" onClick={() => setStartConnector(true)}>
                                    I Don't have GSP API Credentials
                                </span>
                            </div>
                        }

                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default GSPCredentialsPopup