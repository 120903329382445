const {
  DOMAIN_COBRAND_MAPPING,

  API_BASE_URL,
  API_BASE_URL_GO,
  API_LB_BASE_URL = [],

  SITE_BASE_PATH,
  SITE_LOGO,

  CFG_DEFAULT_UTM_SOURCE = "",

  CFG_GLOBAL_DV_CODE = "",

  IN_MAINTENANCE_MODE = false,
  MAINTENANCE_MESSAGE = "",

  CLIENT_2A_2B_RECO_SCHEDULE_DAY = 15,

  CORPORATE_PLAN_SUBSCRIBER_TEL = "",

  RAZORPAY_KEY = "",

  CFG_CONNECTOR_VERSION_URL = "https://uti.kdkupdate.com/express-connector-2/update.json",
  CFG_CONNECTOR_FAQ_LINK = "",

  CFG_TALLY,

  CFG_PASSWORD_ENCRYPTION_KEY='',
  CFG_PASSWORD_ENCRYPTION_IV='',

  CFG_GSTR1_3B_RETURN_FILE_DOWNLOAD_MODE = 3,
  CFG_SYNC_PORTAL_DATA_MODE = 1,

  CFG_CONNECTOR_LOCAL_PORTS = []

} = require("../config");


const current_sub_domain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
const current_domain = window.location.hostname.split('.').slice(-2).join('.');

const cobrand_mapping_touse = DOMAIN_COBRAND_MAPPING.find((detail, index) => {
  if (current_sub_domain) {
    return detail.domain === current_domain && detail.sub_domain === current_sub_domain;
  }
  return detail.domain === current_domain && detail.sub_domain === '';
}) ?? {};

export const BASE_URL = cobrand_mapping_touse.api_base_url ?? API_BASE_URL;
export const BASE_URL_GO = API_BASE_URL_GO;
export const LB_BASE_URL = API_LB_BASE_URL;

export const BASE_PATH = cobrand_mapping_touse.site_base_path ?? SITE_BASE_PATH;
export const LOGO = cobrand_mapping_touse.site_logo ?? SITE_LOGO;

export const DEFAULT_UTM_SOURCE = CFG_DEFAULT_UTM_SOURCE ?? null;
export const COBRAND_UTM_SOURCE = cobrand_mapping_touse.utm_source ?? null;
export const SIGNUP_AVAILABLE = cobrand_mapping_touse.signup_available ?? true;

export const GLOBAL_DV_CODE = CFG_GLOBAL_DV_CODE;

export const IS_MAINTENANCE_MODE_ENABLED = IN_MAINTENANCE_MODE;
export const MAINTENANCE_MESSAGE_TO_SHOW = MAINTENANCE_MESSAGE;

export const CLIENT_2A_2B_RECO_SCHEDULE_DAY_NUMBER = CLIENT_2A_2B_RECO_SCHEDULE_DAY;

export const CORPORATE_PLAN_SUBSCRIBER_TEL_NUMBER = CORPORATE_PLAN_SUBSCRIBER_TEL;

export const RAZORPAY_PAYMENT_KEY = RAZORPAY_KEY;

// CONNECTOR Related
export const CONNECTOR_LOCAL_PORTS = CFG_CONNECTOR_LOCAL_PORTS;
export const HEADER_CONNECTOR_DIGEST_KEY = 'k-data-dt';
export const CONNECTOR_VERSION_URL = CFG_CONNECTOR_VERSION_URL;
export const CONNECTOR_FAQ_LINK = CFG_CONNECTOR_FAQ_LINK;

// General Headers
export const HEADER_FILE_NAME = 'k-file-name';


export const initialHeaderData = {
  company_name: '',
  gst_number: '',
  mobile_number: '',
  financial_year_formatted: '',
  quarter: '',
  form_type: '',
  full_name: '',
  user_role: '',
  registration_type: '',
  start_fy: '',
  gstr_filed_status: {}
};

export const quarterListForMulti = [{ label: "Apr - Jun", value: 'Q1' }, { label: "Jul - Sep", value: 'Q2' }, { label: "Oct - Dec", value: 'Q3' }, { label: "Jan - Mar", value: 'Q4' }];

export const monthListForMulti = [
  { label: "Apr", value: 'APR' },
  { label: "May", value: 'MAY' },
  { label: "Jun", value: 'JUN' },
  { label: "Jul", value: 'JUL' },
  { label: "Aug", value: 'AUG' },
  { label: "Sep", value: 'SEP' },
  { label: "Oct", value: 'OCT' },
  { label: "Nov", value: 'NOV' },
  { label: "Dec", value: 'DEC' },
  { label: "Jan", value: 'JAN' },
  { label: "Feb", value: 'FEB' },
  { label: "Mar", value: 'MAR' }
];

export const GSTR9C_START_FY = 2020;
export const GSTR1A_START_FY = 2024;
export const IFF_START_FY = 2021;

export const INVOICE_TYPES = [
  { label: "Regular", value: 'REGULAR' },
  { label: "Sez supplies with payment", value: 'SEZ_WITH_PAYMENT' },
  { label: "Sez supplies without payment", value: 'SEZ_WITHOUT_PAYMENT' },
  { label: "Deemed Exp", value: 'DEEMED_EXP' },
  { label: "Intra state supplies attracting IGST", value: 'INTRA_STATE_IGST' }
];

export const TAX_RATES = [
  { label: "0.00%", value: "0.00" },
  { label: "0.10%", value: "0.10" },
  { label: "0.25%", value: "0.25" },
  { label: "1.00%", value: "1.00" },
  { label: "1.50%", value: "1.50" },
  { label: "3.00%", value: "3.00" },
  { label: "5.00%", value: "5.00" },
  { label: "6.00%", value: "6.00" },
  { label: "7.50%", value: "7.50" },
  { label: "12.00%", value: "12.00" },
  { label: "18.00%", value: "18.00" },
  { label: "28.00%", value: "28.00" }
];

export const PLACE_OF_SUPPLY_LIST = [
  { label: "01-Jammu and Kashmir", value: "01" },
  { label: "02-Himachal Pradesh", value: "02" },
  { label: "03-Punjab", value: "03" },
  { label: "04-Chandigarh", value: "04" },
  { label: "05-Uttarakhand", value: "05" },
  { label: "06-Haryana", value: "06" },
  { label: "07-Delhi", value: "07" },
  { label: "08-Rajasthan", value: "08" },
  { label: "09-Uttar Pradesh", value: "09" },
  { label: "10-Bihar", value: "10" },
  { label: "11-Sikkim", value: "11" },
  { label: "12-Arunachal Pradesh", value: "12" },
  { label: "13-Nagaland", value: "13" },
  { label: "14-Manipur", value: "14" },
  { label: "15-Mizoram", value: "15" },
  { label: "16-Tripura", value: "16" },
  { label: "17-Meghalaya", value: "17" },
  { label: "18-Assam", value: "18" },
  { label: "19-West Bengal", value: "19" },
  { label: "20-Jharkhand", value: "20" },
  { label: "21-Odisha", value: "21" },
  { label: "22-Chattisgarh", value: "22" },
  { label: "23-Madhya Pradesh", value: "23" },
  { label: "24-Gujarat", value: "24" },
  { label: "26-Dadra and Nagar Haveli and Daman and Diu", value: "26" },
  { label: "27-Maharashtra", value: "27" },
  { label: "28-Andhra Pradesh", value: "28" },
  { label: "29-Karnataka", value: "29" },
  { label: "30-Goa", value: "30" },
  { label: "31-Lakshadweep", value: "31" },
  { label: "32-Kerala", value: "32" },
  { label: "33-Tamil Nadu", value: "33" },
  { label: "34-Puducherry", value: "34" },
  { label: "35-Andaman AND Nicobar Islands", value: "35" },
  { label: "36-Telangana", value: "36" },
  { label: "37-Andhra Pradesh", value: "37" },
  { label: "38-Ladakh", value: "38" },
  { label: "97-Other Territory", value: "97" }
];

export const STATE_UT_LIST = [
  { label: "01-Jammu and Kashmir", value: "01" },
  { label: "02-Himachal Pradesh", value: "02" },
  { label: "03-Punjab", value: "03" },
  { label: "04-Chandigarh", value: "04" },
  { label: "05-Uttarakhand", value: "05" },
  { label: "06-Haryana", value: "06" },
  { label: "07-Delhi", value: "07" },
  { label: "08-Rajasthan", value: "08" },
  { label: "09-Uttar Pradesh", value: "09" },
  { label: "10-Bihar", value: "10" },
  { label: "11-Sikkim", value: "11" },
  { label: "12-Arunachal Pradesh", value: "12" },
  { label: "13-Nagaland", value: "13" },
  { label: "14-Manipur", value: "14" },
  { label: "15-Mizoram", value: "15" },
  { label: "16-Tripura", value: "16" },
  { label: "17-Meghalaya", value: "17" },
  { label: "18-Assam", value: "18" },
  { label: "19-West Bengal", value: "19" },
  { label: "20-Jharkhand", value: "20" },
  { label: "21-Odisha", value: "21" },
  { label: "22-Chattisgarh", value: "22" },
  { label: "23-Madhya Pradesh", value: "23" },
  { label: "24-Gujarat", value: "24" },
  { label: "25-Daman and Diu", value: "25" },
  { label: "26-Dadra and Nagar Haveli and Daman and Diu", value: "26" },
  { label: "27-Maharashtra", value: "27" },
  { label: "28-Andhra Pradesh", value: "28" },
  { label: "29-Karnataka", value: "29" },
  { label: "30-Goa", value: "30" },
  { label: "31-Lakshadweep", value: "31" },
  { label: "32-Kerala", value: "32" },
  { label: "33-Tamil Nadu", value: "33" },
  { label: "34-Puducherry", value: "34" },
  { label: "35-Andaman AND Nicobar Islands", value: "35" },
  { label: "36-Telangana", value: "36" },
  { label: "37-Andhra Pradesh", value: "37" },
  { label: "38-Ladakh", value: "38" },
  { label: "97-Other Territory", value: "97" }
];

export const RCM_LIST = [{ value: "Y", label: "Yes" }, { value: "N", label: "No" }];
export const EXPORT_TYPES = [{ value: 'WOPAY', label: 'WOPAY' }, { value: 'WPAY', label: 'WPAY' }];

export const NATURE_OF_DOCUMENTS = [
  { value: 1, label: 'Invoices for outward supply' },
  { value: 2, label: 'Invoices for inward supply from unregistered person' },
  { value: 3, label: 'Revised Invoice' },
  { value: 4, label: 'Debit Note' },
  { value: 5, label: 'Credit Note' },
  { value: 6, label: 'Receipt Voucher' },
  { value: 7, label: 'Payment Voucher' },
  { value: 8, label: 'Refund Voucher' },
  { value: 9, label: 'Delivery Challan for job work' },
  { value: 10, label: 'Delivery Challan for supply on approval' },
  { value: 11, label: 'Delivery Challan in case of liquid gas' },
  { value: 12, label: 'other' }
];

export const UQM_LIST = [
  { value: 'BAG', label: 'BAGS' },
  { value: 'BAL', label: 'BALES' },
  { value: 'BDL', label: 'BUNDLES' },
  { value: 'BKL', label: 'BUCKLES' },
  { value: 'BOU', label: 'BILLION OF UNITS' },
  { value: 'BOX', label: 'BOX' },
  { value: 'BTL', label: 'BOTTLES' },
  { value: 'BUN', label: 'BUNCHES' },
  { value: 'CAN', label: 'CANS' },
  { value: 'CBM', label: 'CUBIC METERS' },
  { value: 'CCM', label: 'CUBIC CENTIMETERS' },
  { value: 'CMS', label: 'CENTIMETERS' },
  { value: 'CTN', label: 'CARTONS' },
  { value: 'DOZ', label: 'DOZENS' },
  { value: 'DRM', label: 'DRUMS' },
  { value: 'GGK', label: 'GREAT GROSS' },
  { value: 'GMS', label: 'GRAMMES' },
  { value: 'GRS', label: 'GROSS' },
  { value: 'GYD', label: 'GROSS YARDS' },
  { value: 'KGS', label: 'KILOGRAMS' },
  { value: 'KLR', label: 'KILOLITRES' },
  { value: 'KME', label: 'KILOMETERS' },
  { value: 'LTR', label: 'LITRES' },
  { value: 'MLT', label: 'MILIMETERS' },
  { value: 'MTR', label: 'METERS' },
  { value: 'MTS', label: 'METRIC TONNES' },
  { value: 'NOS', label: 'NUMBERS' },
  { value: 'PAC', label: 'PACKS' },
  { value: 'PCS', label: 'PIECES' },
  { value: 'PRS', label: 'PAIRS' },
  { value: 'QTL', label: 'QUINTALS' },
  { value: 'ROL', label: 'ROLLS' },
  { value: 'SET', label: 'SETS' },
  { value: 'SQF', label: 'SQUARE FEET' },
  { value: 'SQM', label: 'SQUARE METERS' },
  { value: 'SQY', label: 'SQUARE YARDS' },
  { value: 'TBS', label: 'TABLETS' },
  { value: 'TGM', label: 'TEN GROSS' },
  { value: 'THD', label: 'THOUSANDS' },
  { value: 'TON', label: 'TONNES' },
  { value: 'TUB', label: 'TUBES' },
  { value: 'UGS', label: 'US GALLONS' },
  { value: 'UNT', label: 'UNITS' },
  { value: 'YDS', label: 'YARDS' },
  { value: 'OTH', label: 'OTHERS' },
  { value: 'NA', label: 'NA' }
];

export const SUPPLY_TYPES = [{ value: 'INTER', label: 'Inter State' }, { value: 'INTRA', label: 'Intra State' }];
export const UR_TYPES = [
  { value: 'B2CL', label: 'B2CL' },
  { value: 'EXPWP', label: 'EXPWP' },
  { value: 'EXPWOP', label: 'EXPWOP' }
];
export const GSTR_DOCUMENT_TYPES = [
  { value: 'C', label: 'Credit Note' },
  { value: 'D', label: 'Debit Note' }
];
export const E_INVOICE_TYPES = [
  { label: 'E-Invoice', value: 'E_INVOICE' },
  { label: 'E-WayBill', value: 'E_WAYBILL' }
];

export const TAX_RATES_WITH_APPLICABLE_RATES = [
  { label: "0.00% (100%)", value: "0-100" },
  { label: "0.10% (100%)", value: "0.1-100" },
  { label: "0.25% (100%)", value: "0.25-100" },
  { label: "1.00% (100%)", value: "1-100" },
  { label: "1.50% (100%)", value: "1.5-100" },
  { label: "3.00% (100%)", value: "3-100" },
  { label: "5.00% (100%)", value: "5-100" },
  { label: "6.00% (100%)", value: "6-100" },
  { label: "7.50% (100%)", value: "7.5-100" },
  { label: "12.00% (100%)", value: "12-100" },
  { label: "18.00% (100%)", value: "18-100" },
  { label: "28.00% (100%)", value: "28-100" },
  { label: "0.00% (65%)", value: "0-65" },
  { label: "0.10% (65%)", value: "0.1-65" },
  { label: "0.25% (65%)", value: "0.25-65" },
  { label: "1.00% (65%)", value: "1-65" },
  { label: "1.50% (65%)", value: "1.5-65" },
  { label: "3.00% (65%)", value: "3-65" },
  { label: "5.00% (65%)", value: "5-65" },
  { label: "6.00% (65%)", value: "6-65" },
  { label: "7.50% (65%)", value: "7.5-65" },
  { label: "12.00% (65%)", value: "12-65" },
  { label: "18.00% (65%)", value: "18-65" },
  { label: "28.00% (65%)", value: "28-65" },

];

export const UPLOAD_ACTIVITY_STATUS = [
  { label: "Ready for Upload", value: 'READY', color: 'rgb(51, 105, 30)' },
  { label: "Uploaded on Portal", value: 'UPLOADED', color: '#689e39' },
  { label: "Warning", value: 'WARNING', color: '#ffdb99' },
  { label: "Error", value: 'ERROR', color: '#e57373' }
];

export const GSTR1_STATUS_FILTER_ACTIVITY = [
  { label: "GSTR1 Filed", value: 'R1FILED'},
  { label: "New Added", value: 'NEW' },
  { label: "Amended", value: 'AMENDED' }
];

export const MONTH_NUMBER_TO_TEXT = {
  "1": "JAN",
  "2": "FEB",
  "3": "MAR",
  "4": "APR",
  "5": "MAY",
  "6": "JUN",
  "7": "JUL",
  "8": "AUG",
  "9": "SEP",
  "10": "OCT",
  "11": "NOV",
  "12": "DEC"
};
export const SYNC_REATTEMPTS_DAYS = 7;
export const SYNC_THRESHOLD_TS = "07/05/2021 23:59:59";
export const MONTH_NUMBER_TO_FULL_TEXT = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};
export const POSWISE = ["CORPORATE"];
export const MONTHWISE = ["CA", "CORPORATE"];
export const RATEWISE = ["CA", "CORPORATE"];
export const PARTYWISE = ["CORPORATE"];
export const COMPONENTWISE = ["CA", "CORPORATE"];
export const SECTIONWISE = ["CA", "CORPORATE"];
export const CORPORATE_ONLY_REPORT = "This report is available for Corporate/Enterprise Plan users only. Please contact us, if you wish to upgrade.";
export const CORPORATE_ONLY_FEATURE = "This feature is available for Corporate/Enterprise Plan users only. Please contact us, if you wish to upgrade.";

export const TASK_QUEUE = 'task_n_notification_queue_v1';
export const PROCESS_TYPES = {
  "GSTR1_SAVE": "GSTR 1",
  "IFF_SAVE": "IFF",
};

export const MONTH_DROPDOWN = [
  { label: "April", value: '4' },
  { label: "May", value: '5' },
  { label: "June", value: '6' },
  { label: "July", value: '7' },
  { label: "August", value: '8' },
  { label: "September", value: '9' },
  { label: "October", value: '10' },
  { label: "November", value: '11' },
  { label: "December", value: '12' },
  { label: "January", value: '1' },
  { label: "February", value: '2' },
  { label: "March", value: '3' }
];

export const ADDON_GST_TAX_RATE = 18.0;

export const B2B_MISMATCH = [
  { label: 'GSTIN', value: 'gst_number' },
  { label: 'Inv Date', value: 'invoice_date' },
  { label: 'Inv Value', value: 'invoice_value' },
  { label: 'POS', value: 'place_of_supply' },
  { label: 'RCM', value: 'rcm' },
  { label: 'Inv Type', value: 'invoice_type' },
  { label: 'Taxable Value', value: 'sum_taxable_amt' },
  { label: 'Appl. % of Tax Rate', value: 'applicable_tax_rate' },
  { label: 'Rate', value: 'rate' },
  { label: 'IGST', value: 'sum_igst_amt' },
  { label: 'CGST', value: 'sum_cgst_amt' },
  { label: 'SGST', value: 'sum_sgst_amt' },
  { label: 'Cess', value: 'sum_cess_amt' }
]

export const B2CL_MISMATCH = [
  { label: 'Inv Date', value: 'invoice_date' },
  { label: 'Inv Value', value: 'invoice_value' },
  { label: 'POS', value: 'place_of_supply' },
  { label: 'Taxable Value', value: 'sum_taxable_amt' },
  { label: 'Appl. % of Tax Rate', value: 'applicable_tax_rate' },
  { label: 'Rate', value: 'rate' },
  { label: 'IGST', value: 'sum_igst_amt' },
  { label: 'CGST', value: 'sum_cgst_amt' },
  { label: 'SGST', value: 'sum_sgst_amt' },
  { label: 'Cess', value: 'sum_cess_amt' },
]

export const EXPORT_MISMATCH = [
  { label: 'Inv Date', value: 'invoice_date' },
  { label: 'Inv Value', value: 'invoice_value' },
  { label: 'Export Type', value: 'export_type' },
  { label: 'Taxable Value', value: 'sum_taxable_amt' },
  { label: 'Rate', value: 'rate' },
  { label: 'IGST', value: 'sum_igst_amt' },
  { label: 'Cess', value: 'sum_cess_amt' },
]

export const CDNR_MISMATCH = [
  { label: 'GSTIN', value: 'gst_number' },
  { label: 'CN/DN Date', value: 'note_date' },
  { label: 'CN/DN Value', value: 'note_value' },
  { label: 'POS', value: 'place_of_supply' },
  { label: 'RCM', value: 'rcm' },
  { label: 'CN/DN', value: 'note_type' },
  { label: 'Note Supply Type', value: 'invoice_type' },
  { label: 'Taxable Value', value: 'sum_taxable_amt' },
  { label: 'Appl. % of Tax Rate', value: 'applicable_tax_rate' },
  { label: 'Rate', value: 'rate' },
  { label: 'IGST', value: 'sum_igst_amt' },
  { label: 'CGST', value: 'sum_cgst_amt' },
  { label: 'SGST', value: 'sum_sgst_amt' },
  { label: 'Cess', value: 'sum_cess_amt' },
]

export const CDNUR_MISMATCH = [
  { label: 'CN/DN Date', value: 'note_date' },
  { label: 'CN/DN Value', value: 'note_value' },
  { label: 'POS', value: 'place_of_supply' },
  { label: 'CN/DN', value: 'note_type' },
  { label: 'UR Type', value: 'ur_type' },
  { label: 'Taxable Value', value: 'sum_taxable_amt' },
  { label: 'Appl. % of Tax Rate', value: 'applicable_tax_rate' },
  { label: 'Rate', value: 'rate' },
  { label: 'IGST', value: 'sum_igst_amt' },
  { label: 'CGST', value: 'sum_cgst_amt' },
  { label: 'SGST', value: 'sum_sgst_amt' },
  { label: 'Cess', value: 'sum_cess_amt' },
]


export const CLIENT_RECORD_IN_ONE_PAGE = 17;

//if current time is betwwen below time then sync process will be check 
export const DAILY_TALLY_SYNC_TIME = CFG_TALLY?.DAILY_TALLY_SYNC_TIME ?? {
  start_hour: 9, start_min: 30, end_hour: 20, end_min: 30
}

// if current time is b/w above time , then check if sync data is exist in local data and
//  current time is greatr then below var as min then it will for next step 
export const LOCAL_CHECK_TALLY_SHOULD_SYNC_AFTER_MINTUES = CFG_TALLY?.LOCAL_CHECK_TALLY_SHOULD_SYNC_AFTER_MINTUES ?? 100

//if above condition passed , then last sync time and current time differene should be greater than below var value
export const API_CHECK_TALLY_SHOULD_SYNC_AFTER_MINTUES = CFG_TALLY?.API_CHECK_TALLY_SHOULD_SYNC_AFTER_MINTUES ?? 60



export const TALLY_DELETE_DAYS = CFG_TALLY?.TALLY_DELETE_DAYS ?? 60
export const TALLY_ENABLE_NOW_POPUP_LAST_DATE = CFG_TALLY?.TALLY_ENABLE_NOW_POPUP_LAST_DATE ?? 60
export const TALLY_ENABLE_NOW_POPUP_KEY = "tally_enable_now_popup_shown_for_"

export const CONNECTOR_ERRORS_MAPPING = {
  "tally.company_not_found": 'No company is found. Please ensure that Tally is running and company is opened.',
  "tally.no_company": 'No company is open. Please open company in Tally and try again.',
  "tally.not_running": 'Tally is not running.',
  "tally.not_running_on_port": "Tally is not running on this port.",
  "tally.company_gstin_mismatch": "Company GST No. does not match.",
  "gst.invalid_credentials": "Invalid GST Login Credentials! Please enter correct credentials.",
  "gst.invalid_captcha_ref": "Invalid data found in the request, Please try again.",
  "gst.invalid_captcha_text": "Enter correct captcha as shown.",
  "gst.captcha_failure": "Please ensure that your internet connection is stable and the GST portal is accessible.",
  "gst.session_timeout": "Your session has expired! Please re-login with your credentials.",
  "gst.password_expired": "Your password has expired! Please reset your password.",
  "gst.account_locked": "It seems this account is locked on GST Portal. Please unlock and try again.",
  "gst.mismatch_gstin": "GSTIN did not match with the already logged-in GST portal session.",
  "gst.unknown_error": "Please ensure that your internet connection is stable and the GST portal is accessible.",
  "ewb.invalid_credentials": "Invalid EWB Login Credentials! Please enter correct credentials.",
  "ewb.invalid_captcha_ref": "Invalid data found in the request, Please try again.",
  "ewb.invalid_captcha_text": "Enter correct captcha as shown.",
  "ewb.captcha_failure": "Please ensure that your internet connection is stable and the EWB portal is accessible.",
  "ewb.session_timeout": "Your session has expired! Please re-login with your credentials.",
  "ewb.password_expired": "Your password has expired! Please reset your password.",
  "ewb.account_locked": "It seems this account is locked on EWB Portal. Please unlock and try again.",
  "ewb.unknown_error": "Something went wrong while logging to the EWB portal! Please try again.",
  "ewb.mismatch_gstin": "GSTIN did not match with the already logged-in EWB portal session.",
}

export const  TYPE_OF_SUPPLY = [
  {label: "u/s 52- TCS", value: "US_52_TCS"},
  {label: "u/s 9(5)", value: "US_9_5"}
]

export const TAX_RATES_14TO15_APPLICABLE_RATES = [
  { label: "0.00%", value: "0-100" },
  { label: "0.10%", value: "0.1-100" },
  { label: "0.25%", value: "0.25-100" },
  { label: "1.00%", value: "1-100" },
  { label: "1.50%", value: "1.5-100" },
  { label: "3.00%", value: "3-100" },
  { label: "5.00%", value: "5-100" },
  { label: "6.00%", value: "6-100" },
  { label: "7.50%", value: "7.5-100" },
  { label: "12.00%", value: "12-100" },
  { label: "18.00%", value: "18-100" },
  { label: "28.00%", value: "28-100" },
];

export const INVOICE_TYPES_14TO15 = [
  { label: "Regular", value: 'REGULAR' },
  { label: "Sez supplies with payment", value: 'SEZ_WITH_PAYMENT' },
  { label: "Sez supplies without payment", value: 'SEZ_WITHOUT_PAYMENT' },
  { label: "Deemed Exp", value: 'DEEMED_EXP' },
];


export const PASSWORD_ENCRYPTION_KEY = CFG_PASSWORD_ENCRYPTION_KEY;
export const PASSWORD_ENCRYPTION_IV  = CFG_PASSWORD_ENCRYPTION_IV;

export const ACCOUNT_LOCKED_MESSAGE = 'Your account is locked due to incorrect password attempts. Please reset your password.';

//Form 3CD Start year
export const FORM_3CD_START_YEAR = 2023


export const STATE_LIST = [
  { text: "Jammu and Kashmir", value: "01" },
  { text: "Himachal Pradesh", value: "02" },
  { text: "Punjab", value: "03" },
  { text: "Chandigarh", value: "04" },
  { text: "Uttarakhand", value: "05" },
  { text: "Haryana", value: "06" },
  { text: "Delhi", value: "07" },
  { text: "Rajasthan", value: "08" },
  { text: "Uttar Pradesh", value: "09" },
  { text: "Bihar", value: "10" },
  { text: "Sikkim", value: "11" },
  { text: "Arunachal Pradesh", value: "12" },
  { text: "Nagaland", value: "13" },
  { text: "Manipur", value: "14" },
  { text: "Mizoram", value: "15" },
  { text: "Tripura", value: "16" },
  { text: "Meghalaya", value: "17" },
  { text: "Assam", value: "18" },
  { text: "West Bengal", value: "19" },
  { text: "Jharkhand", value: "20" },
  { text: "Odisha", value: "21" },
  { text: "Chattisgarh", value: "22" },
  { text: "Madhya Pradesh", value: "23" },
  { text: "Gujarat", value: "24" },
  { text: "Daman and Diu", value: "25" },
  { text: "Dadra and Nagar Haveli and Daman and Diu", value: "26" },
  { text: "Maharashtra", value: "27" },
  { text: "Andhra Pradesh", value: "28" },
  { text: "Karnataka", value: "29" },
  { text: "Goa", value: "30" },
  { text: "Lakshadweep", value: "31" },
  { text: "Kerala", value: "32" },
  { text: "Tamil Nadu", value: "33" },
  { text: "Puducherry", value: "34" },
  { text: "Andaman AND Nicobar Islands", value: "35" },
  { text: "Telangana", value: "36" },
  { text: "Andhra Pradesh", value: "37" },
  { text: "Ladakh", value: "38" },
  { text: "Other Territory", value: "97" }
];

export const ACCOUNT_TYPE = [
  { text: "Savings", value: "SAVING" },
  { text: "Current", value: "CURRENT" },
  { text: "Cash Credit", value: "CASH_CREDIT" },
  { text: "Over Draft", value: "OVER_DRAFT" },
];

export const CONSTITUION_OF_BUSINESS = [
  { label: 'Proprietorship', value: 'Proprietorship' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'Private Limited Company', value: 'Private Limited Company' },
  { label: 'Public Limited Company', value: 'Public Limited Company' },
  { label: 'Public Sector Undertaking', value: 'Public Sector Undertaking' },
  { label: 'Limited Liability Partnership', value: 'Limited Liability Partnership' },
  { label: 'Local Authority', value: 'Local Authority' },
  { label: 'Government Department', value: 'Government Department' },
  { label: 'Hindu Undivided Family', value: 'Hindu Undivided Family' },
  { label: 'Society/ Club/ Trust/ AOP', value: 'Society/ Club/ Trust/ AOP' },
  { label: 'Statutory Body', value: 'Statutory Body' },
  { label: 'Unlimited Company', value: 'Unlimited Company' },
  { label: 'Foreign Company', value: 'Foreign Company' },
  { label: 'Foreign Limited Liability Partnership', value: 'Foreign Limited Liability Partnership' },
]

export const MAX_IMPORT_FILE_SIZE_MB = 2;

export const GSTR1_3B_RETURN_FILE_DOWNLOAD_MODE = CFG_GSTR1_3B_RETURN_FILE_DOWNLOAD_MODE;

export const SYNC_PORTAL_DATA_MODE = CFG_SYNC_PORTAL_DATA_MODE;

export const INVOICE_STATUS = {
  MATCHED: { value: 'Matched', background: "#c4e1a4", color: "#689e39" },
  PARTIAL_MATCHED: { value: 'Partial Matched', background: "#ff9802", color: "#212529" },
  MISSING_IN_R1: { value: 'Missing in R1', background: "#ffcdd2", color: "#f54337" },
  MISSING_IN_EWB: { value: 'Missing in EWB', background: "#f54337", color: "#000000" },
  CANCELLED: { value: 'Can./Rej./Dis.', background: "#e0e0e0", color: "#000000" },
  REJECTED: { value: 'Can./Rej./Dis.', background: "#e0e0e0", color: "#000000" },
  DISCARDED: { value: 'Can./Rej./Dis.', background: "#e0e0e0", color: "#000000" },
};

export const SUBFILTER2A2B = {
  1: "Perfect Matched",
  2: "Manually Matched (Single /Multi Linked)",
  3: "Doc. Date Different", 
  4: "Books And 2B/2A Period differ", 
  5: "RCM Differ", 
  6: "POS Differ",
  21: "Only Doc. No. Mismatch", 
  22: "Taxable Value Mismatch", 
  23: "Tax Mismatch", 
  26: "RCM Differ", 
  27: "POS Differ",  
  28: "Other Partially Matched",
  41: "Yes", 
  42: "No",
  51: "Yes", 
  52: "No"
  
};

// [1: Perfect Matched, 2: Manually Matched (Single /Multi Linked), 3: Doc. Date Different, 4: Books And 2B/2A Period differ, 5: RCM Differ, 6: POS Differ]
export const MATCHED_SUBFILTER_CODE = [1, 2, 3, 4, 5, 6]; 
// [7: Only Doc. No. Mismatch, 8: Taxable Value  Mismatch, 9: Tax Mismatch, 10: RCM Differ, 11: POS Differ,  12: Other Partially Matched]
export const PARTIALMATCHED_SUBFILTER_CODE = [21, 22, 23, 26, 27, 28]; 
// [13: YES, 14: NO]
export const RCM_SUBFILTER_CODE = [41, 42]; 
export const ITCAVAIL_SUBFILTER_CODE = [51, 52];

export const CAN_REJ_DISCARD = ["CANCELLED", "REJECTED", "DISCARDED"];

