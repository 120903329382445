import moment from 'moment';
import {
  BASE_PATH, CONNECTOR_LOCAL_PORTS, TALLY_DELETE_DAYS, PASSWORD_ENCRYPTION_KEY, PASSWORD_ENCRYPTION_IV, STATE_UT_LIST,
  initialHeaderData, PLACE_OF_SUPPLY_LIST
} from '../app/constants';
import { getConnectionStatus } from '../api/localConnectorAPI';
import { LOGIN_PATHS } from '../App';
import { getAuthToken, updateAuthToken } from './AuthToken';
import history from '../app/history';
import store from '../app/store';
import { setHeader } from '../features/header/headerSlice';

export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

/**
 * Function to get assessment years
 * @return: [
    {2020: '2020-21'},
    {2021: '2021-22'},
   ]
 */
export function getAssessmentYears() {
  const AYs = [];
  let startYear = 2020;
  const currentYear = moment().format('YYYY');
  // const currentMonth = moment().format('M');
  // const endYear = currentMonth > 3 ? parseInt(currentYear, 10) + 1 : parseInt(currentYear, 10);
  const endYear = parseInt(currentYear, 10);
  while (startYear <= endYear) {
    AYs.push({ label: `${startYear}-${(startYear + 1) % 100}`, value: startYear });
    startYear += 1;
  }

  return AYs;
}

export function getCurrentAssessmentYear() {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  if (currentMonth > 3) return parseInt(currentYear, 10);

  return parseInt(currentYear, 10) - 1;
}

export function getCurrentFinancialYear(mohth_gt = 3) {
  const currentYear = parseInt(moment().format('YYYY'), 10);
  const currentMonth = parseInt(moment().format('M'), 10);
  if (currentMonth > mohth_gt) {
    let next_year = currentYear + 1
    return { id: `${currentYear}`, text: `${currentYear}-${next_year.toString().substr(2, 2)}` };
  }

  let prev_year = currentYear;
  return { id: `${currentYear - 1}`, text: `${currentYear - 1}-${prev_year.toString().substr(2, 2)}` };
}

export function getFinancialYears(registration_date) {
  let startYear = registration_date ? parseInt(moment(registration_date).format('YYYY')) : 2017;
  if (registration_date) {
    let registrationMonth = parseInt(moment(registration_date).format('M'));
    startYear = registrationMonth > 3 ? startYear : startYear - 1;
  }

  const currentMonth = parseInt(moment().format('M'));
  const currentYear = parseInt(moment().format('YYYY'));
  const years = [];
  //const startYear = 2017;
  let endYear = currentMonth > 3 ? currentYear : currentYear - 1;
  while (endYear >= startYear) {
    let next_year = endYear + 1;
    years.push({ text: `${endYear}-${next_year.toString().substr(2, 2)}`, value: `${endYear}` });
    endYear = endYear - 1;
  }
  return years;
}

/*working code for month selection ! */

export function getFinancialMonths(year, registration_date, sorting = false, showListTillCurrentMonth = false) {
  let registraion_month_first_date = moment(registration_date).format("YYYY") + "-" + moment(registration_date).format("MM") + "-01";
  let next_year = parseInt(year, 10) + 1;
  let display_year = moment(year + "-01-01").format("YY");
  let display_next_year = moment(next_year + "-01-01").format("YY");

  let current_year = "";
  let current_month = "";

  if (!showListTillCurrentMonth) {
    current_year = moment().format("YY");
    current_month = moment().format("M");

  } else {
    current_year = moment().year(); // Use moment().year() to get full year
    current_month = moment().month() + 1; // moment().month() returns 0-based index, so add 1
  }

  let monthList = [{ text: `Apr ${display_year}`, month: 'Apr', month_fullname: 'April', year: year, id: 'APR', value: 4, disable: true },
  { text: `May ${display_year}`, month: 'May', month_fullname: 'May', year: year, id: 'MAY', value: 5, disable: true },
  { text: `Jun ${display_year}`, month: 'Jun', month_fullname: 'June', year: year, id: 'JUN', value: 6, disable: true },
  { text: `Jul ${display_year}`, month: 'Jul', month_fullname: 'July', year: year, id: 'JUL', value: 7, disable: true },
  { text: `Aug ${display_year}`, month: 'Aug', month_fullname: 'August', year: year, id: 'AUG', value: 8, disable: true },
  { text: `Sep ${display_year}`, month: 'Sep', month_fullname: 'September', year: year, id: 'SEP', value: 9, disable: true },
  { text: `Oct ${display_year}`, month: 'Oct', month_fullname: 'October', year: year, id: 'OCT', value: 10, disable: true },
  { text: `Nov ${display_year}`, month: 'Nov', month_fullname: 'November', year: year, id: 'NOV', value: 11, disable: true },
  { text: `Dec ${display_year}`, month: 'Dec', month_fullname: 'December', year: year, id: 'DEC', value: 12, disable: true },
  { text: `Jan ${display_next_year}`, month: 'Jan', month_fullname: 'January', year: next_year, id: 'JAN', value: 1, disable: true },
  { text: `Feb ${display_next_year}`, month: 'Feb', month_fullname: 'February', year: next_year, id: 'FEB', value: 2, disable: true },
  { text: `Mar ${display_next_year}`, month: 'Mar', month_fullname: 'March', year: next_year, id: 'MAR', value: 3, disable: true }];

  monthList.map((data, index) => {
    if (moment(data.year + '-' + data.value + '-01').unix() >= moment(registraion_month_first_date).unix()) {
      if (parseInt(current_year) === parseInt(display_year) && parseInt(current_month) < parseInt(data.value)) {
        monthList[index].disable = true;
      } else if (parseInt(current_year) === parseInt(display_year) && parseInt(data.value) < 4) {
        monthList[index].disable = true;
      } else {
        monthList[index].disable = false;
      }
    }
    return true
  })

  if (sorting) {
    // Sort monthList in descending order by year and month value
    monthList.sort((a, b) => {
      if (parseInt(a.year, 10) === parseInt(b.year, 10)) {
        return parseInt(b.value, 10) - parseInt(a.value, 10); // Sort by month value if years are the same
      } else {
        return parseInt(b.year, 10) - parseInt(a.year, 10); // Sort by year if years are different
      }
    });
  }

  if (showListTillCurrentMonth) {
    // Filter monthList to include only months up to current month and year
    monthList = monthList.filter(month => {
      if (parseInt(month.year, 10) < current_year) {
        return true; // Include all months of previous years
      } else if (parseInt(month.year, 10) === current_year && parseInt(month.value, 10) <= current_month) {
        return true; // Include months of current year up to current month
      }
      return false;
    });
  }

  return monthList;
}


export function getFinancialMonthsReco(selectedYears) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed

  const months = [];

  // Custom mapping for month abbreviations
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthFullNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // If selectedYears is not an array or it's empty, return empty array
  if (!Array.isArray(selectedYears) || selectedYears.length === 0) {
    return months;
  }

  // Iterate over each selectedYear in the array
  selectedYears.forEach(selectedYear => {
    // If selectedYear is not a string or does not match the expected format, skip to the next iteration
    if (typeof selectedYear !== 'string' || selectedYear.length !== 4 || isNaN(parseInt(selectedYear, 10))) {
      console.error('Invalid year:', selectedYear);
      return; // Skip to next iteration
    }

    const numericYear = parseInt(selectedYear, 10);

    // Include financial year's months from April to March of the next year
    for (let monthIndex = 4; monthIndex <= 15; monthIndex++) { // Loop from April to March of next year
      const monthOfYear = monthIndex > 12 ? monthIndex - 12 : monthIndex; // Get the month within the year (1-indexed)
      const yearOffset = monthIndex > 12 ? 1 : 0; // Calculate the year offset
      const year = numericYear + yearOffset;

      // Skip months greater than the current month and year
      if (year > currentYear || (year === currentYear && monthOfYear > currentMonth)) {
        continue;
      }

      //const date = new Date(year, monthOfYear - 1); // Create date object for the month

      months.push({
        text: `${monthNames[monthOfYear - 1]}-${year.toString().substr(2)}`,
        month_fullname: monthFullNames[monthOfYear - 1],
        value: monthOfYear,
        year: year,
        disable: false,  // Disable future months
        checked: false  // Initially, months are not checked
      });
    }
  });

  return months;
}




export function getFormattedDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}



export function getFinancialQuarters(year) {
  let next_year = parseInt(year, 10) + 1;
  let quarterList = [];
  if (parseInt(year, 10) === 2017) {
    quarterList = [{ text: `Jul - Sep ${year}`, id: 'Q2' },
    { text: `Oct - Dec ${year}`, id: 'Q3' },
    { text: `Jan - Mar ${next_year}`, id: 'Q4' }];
  }
  else {
    quarterList = [{ text: `Apr - Jun ${year}`, id: 'Q1' },
    { text: `Jul - Sep ${year}`, id: 'Q2' },
    { text: `Oct - Dec ${year}`, id: 'Q3' },
    { text: `Jan - Mar ${next_year}`, id: 'Q4' }];
  }
  return quarterList;
}

export function getFinancialQuarters2(year) {
  let quarterList = [];
  if (parseInt(year, 10) === 2017) {
    quarterList = [{ text: `Jul - Sep`, id: 'Q2' },
    { text: `Oct - Dec`, id: 'Q3' },
    { text: `Jan - Mar`, id: 'Q4' }];
  }
  else {
    quarterList = [{ text: `Apr - Jun`, id: 'Q1' },
    { text: `Jul - Sep`, id: 'Q2' },
    { text: `Oct - Dec`, id: 'Q3' },
    { text: `Jan - Mar`, id: 'Q4' }];
  }
  return quarterList;
}

export function getCurrentQuarter() {
  const currentMonth = moment().format('M');
  if (currentMonth > 3 && currentMonth <= 6) return 'Q1';
  if (currentMonth > 6 && currentMonth <= 9) return 'Q2';
  if (currentMonth > 9 && currentMonth <= 12) return 'Q3';
  return 'Q4';
}

export function checkForAutoLogin() {
  if (window.location.hash) {
    let token = window.location.hash.substring(1);
    updateAuthToken(token);
    let url = window.location.pathname + window.location.search;
    window.history.pushState({}, "", url);
  }
}

export function isTokenValid() {
  checkForAutoLogin();
  // Get auth token - dont fail if invalid
  if (!getAuthToken(false)) {
    saveRedirectUrl();
    store.dispatch(setHeader(initialHeaderData))
    return false;
  }

  return true;
}

export function redirectToLoginPage() {
  history.push(`${BASE_PATH}login`)
}

export function saveRedirectUrl() {
  if (!LOGIN_PATHS.includes(window.location.pathname)) {
    let data = { path: window.location.pathname + window.location.search, time: moment().add(1, 'hours').unix() };
    localStorage.setItem('redirect-url', JSON.stringify(data));
  }
}

export function getRedirectUrl() {
  let redirect_url = localStorage.getItem('redirect-url');
  if (!redirect_url) return false;

  redirect_url = JSON.parse(redirect_url);

  if (redirect_url.time > moment.unix()) return false;
  return redirect_url.path;

}

export function removeRedirectUrl() {
  localStorage.removeItem('redirect-url');
}


export function getFinancialMonthsMulti(year) {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  let next_year = parseInt(year, 10) + 1;
  let monthList = [];
  if (parseInt(year, 10) === 2017) {
    monthList = [{ label: `Jul ${year}`, value: 'JUL', future_month: currentMonth < 7 && currentYear === year ? true : false, indexNumber: 7 },
    { label: `Aug ${year}`, value: 'AUG', future_month: currentMonth < 8 && currentYear === year ? true : false, indexNumber: 8 },
    { label: `Sep ${year}`, value: 'SEP', future_month: currentMonth < 9 && currentYear === year ? true : false, indexNumber: 9 },
    { label: `Oct ${year}`, value: 'OCT', future_month: currentMonth < 10 && currentYear === year ? true : false, indexNumber: 10 },
    { label: `Nov ${year}`, value: 'NOV', future_month: currentMonth < 11 && currentYear === year ? true : false, indexNumber: 11 },
    { label: `Dec ${year}`, value: 'DEC', future_month: currentMonth < 12 && currentYear === year ? true : false, indexNumber: 12 },
    { label: `Jan ${next_year}`, value: 'JAN', future_month: currentMonth > 3 && currentYear === year ? true : false, indexNumber: 1 },
    { label: `Feb ${next_year}`, value: 'FEB', future_month: currentYear === year && (currentMonth < 2 || currentMonth > 3) ? true : false, indexNumber: 2 },
    { label: `Mar ${next_year}`, value: 'MAR', future_month: currentYear === year && (currentMonth < 3 || currentMonth > 3) ? true : false, indexNumber: 3 }];
  }
  else {
    monthList = [{ label: `Apr ${year}`, value: 'APR', future_month: currentMonth < 4 && currentYear === year ? true : false, indexNumber: 4 },
    { label: `May ${year}`, value: 'MAY', future_month: currentMonth < 5 && currentYear === year ? true : false, indexNumber: 5 },
    { label: `Jun ${year}`, value: 'JUN', future_month: currentMonth < 6 && currentYear === year ? true : false, indexNumber: 6 },
    { label: `Jul ${year}`, value: 'JUL', future_month: currentMonth < 7 && currentYear === year ? true : false, indexNumber: 7 },
    { label: `Aug ${year}`, value: 'AUG', future_month: currentMonth < 8 && currentYear === year ? true : false, indexNumber: 8 },
    { label: `Sep ${year}`, value: 'SEP', future_month: currentMonth < 9 && currentYear === year ? true : false, indexNumber: 9 },
    { label: `Oct ${year}`, value: 'OCT', future_month: currentMonth < 10 && currentYear === year ? true : false, indexNumber: 10 },
    { label: `Nov ${year}`, value: 'NOV', future_month: currentMonth < 11 && currentYear === year ? true : false, indexNumber: 11 },
    { label: `Dec ${year}`, value: 'DEC', future_month: currentMonth < 12 && currentYear === year ? true : false, indexNumber: 12 },
    { label: `Jan ${next_year}`, value: 'JAN', future_month: currentMonth > 3 && currentYear === year ? true : false, indexNumber: 1 },
    { label: `Feb ${next_year}`, value: 'FEB', future_month: currentYear === year && (currentMonth < 2 || currentMonth > 3) ? true : false, indexNumber: 2 },
    { label: `Mar ${next_year}`, value: 'MAR', future_month: currentYear === year && (currentMonth < 3 || currentMonth > 3) ? true : false, indexNumber: 3 }];
  }

  return monthList;
}

export function getFinancialQuartersMulti(year) {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  let next_year = parseInt(year, 10) + 1;
  let quarterList = [];
  if (parseInt(year, 10) === 2017) {
    quarterList = [{ label: `Jul - Sep ${year}`, value: 'Q2', future_month: false },
    { label: `Oct - Dec ${year}`, value: 'Q3', future_month: currentMonth < 10 && currentYear === year ? true : false },
    { label: `Jan - Mar ${next_year}`, value: 'Q4', future_month: currentMonth > 3 && currentYear === year ? true : false }];
  }
  else {
    quarterList = [{ label: `Apr - Jun ${year}`, value: 'Q1', future_month: false },
    { label: `Jul - Sep ${year}`, value: 'Q2', future_month: currentMonth < 7 && currentYear === year ? true : false },
    { label: `Oct - Dec ${year}`, value: 'Q3', future_month: currentMonth < 10 && currentYear === year ? true : false },
    { label: `Jan - Mar ${next_year}`, value: 'Q4', future_month: currentMonth > 3 && currentYear === year ? true : false }];
  }
  return quarterList;
}

export function formatNumber(num) {
  if (isNaN(num)) return num;

  if (!num) num = 0;
  let n = (Math.round(num * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString('en-IN');
  // eslint-disable-next-line eqeqeq
  if (n == parseInt(n)) ls = ls + ".00";

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) { ls = ls + "0"; }
  return ls;
}

export const FormatNumberDecimal = (
  getValue,
  dotBeforeDigit = 14,
  dotAfterDigit = 2,
  negative = true
) => {
  let val = String(getValue);
  let value = val;
  value = value.replace(/[^\d.-]/g, "");
  if (isNaN(value) && value !== ".") {
    value = value.slice(0, -1);
  }
  let tmp = String(value).split(".");
  if (tmp.length === 1) {
    value = value.replace(/[\D\s_-]+/g, "");
    value = value.slice(0, dotBeforeDigit);

    value = value ? parseFloat(value, 10) : "";
    if (negative === false && String(val)[0] === "-") {
      return "0";
    }
    if (negative === true && String(val)[0] === "-") {
      return "-" + value.toLocaleString("en-IN");
    }
    return value === 0 ? "0" : value.toLocaleString("en-IN");
  } else if (tmp.length === 2) {
    let value1 = tmp[0].replace(/[\D\s_-]+/g, "");
    let value2 = tmp[1].replace(/[\D\s_-]+/g, "");

    if (dotBeforeDigit && dotBeforeDigit > 0) {
      value1 = value1.slice(0, dotBeforeDigit);
    }
    if (dotAfterDigit && dotAfterDigit > 0) {
      value2 = value2.slice(0, dotAfterDigit);
    }

    value1 = value1 ? parseFloat(value1, 10) : 0;
    if (value2[0] === "0") {
      if (value2.length > 1) value2 = "0" + parseFloat(value2, 10);
      else value2 = parseFloat(value2, 10);
    } else {
      value2 = value2 ? parseFloat(value2, 10) : "";
    }

    if (negative === false && String(val)[0] === "-") {
      return "0";
    }
    if (negative === true && String(val)[0] === "-") {
      return "-" + value1.toLocaleString("en-IN") + "." + value2;
    }
    return value1.toLocaleString("en-IN") + "." + value2;
  }
};


//  In case of NaN number, use the second parameter to specify default. If it is false then the original input 
// is send as it is. Else, the value set in out_ifnan is sent.
export function formatAccountsNumber(num, out_ifnan = false, negative_as_red = true, isTooltip = null) {
  if (isNaN(num)) {
    if (out_ifnan !== false) return out_ifnan; else return num;
  }

  if (!num) num = 0;
  let n = (Math.round(num * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString('en-IN');
  // eslint-disable-next-line eqeqeq
  if (n == parseInt(n)) ls = ls + ".00";

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) { ls = ls + "0"; }
  if (n < 0) {
    ls = ls.substring(1);

    if (negative_as_red) {
      return <span title={isTooltip} className="redtextclr">({ls})</span>;
    } else {
      return <span title={isTooltip}>({ls})</span>;
    }
  }
  return ls;
}

export function getFinancialYearsForGSTR(startYear = 2017) {
  const currentMonth = parseInt(moment().format('M'));
  const currentYear = parseInt(moment().format('YYYY'));
  const years = [];
  //const startYear = 2017;
  let endYear = currentMonth > 3 ? currentYear : currentYear - 1;
  while (endYear >= startYear) {
    let next_year = endYear + 1;
    years.push({ label: `${endYear}-${next_year.toString().substr(2, 2)}`, value: `${endYear}` });
    endYear = endYear - 1;
  }
  return years;
}

export function getLastDateOfMonth(month, year) {
  let m = month < 10 ? `0${month}` : month;
  //let y = month < 4 ? parseInt(year, 10) + 1 : year;
  let y = year;
  let date = `01-${m}-${y}`;

  if (moment().unix() < moment(date, 'DD-MM-YYYY').clone().endOf('month').unix()) {
    return moment().format('DD/MM/YYYY');
  }
  else {
    return moment(date, 'DD-MM-YYYY').clone().endOf('month').format('DD/MM/YYYY');
  }
}

/*Google conversionAdwordsTracking_code tag 12032022 for ads tracking Signup
** on SingUpScreen  ** Install By MKT **/
export function conversionAdwordsTracking_code() {
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }
  gtag('event', 'conversion', {
    'send_to': 'AW-947108503/HCpnCIig06gDEJf1zsMD',
    'value': 1.0,
    'currency': 'Rupee',
  });
}

/*Google conversionAdwordsTracking tag 19082023 for ads conversion Signup
** on SingUpScreen  ** Install By MKT **/
export function conversionAdwordsTracking() {
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }
  gtag('event', 'conversion', {
    'send_to': 'AW-947108503WcKHCNzk-f8BEJf1zsMD'
  });
}


const GSTIN_REGEX = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z1-9A-J]{1}[0-9A-Z]{1}/,
  UIN_REGEX = /[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][0-9A-Z]{1}/,
  NRID_REGEX = /[0-9]{4}[A-Z]{3}[0-9]{5}[N][R][0-9A-Z]{1}/,
  OIDAR_REGEX = /[9][9][0-9]{2}[A-Z]{3}[0-9]{5}[O][S][0-9A-Z]{1}/,
  TDS_REGEX = /[0-9]{2}[A-Z]{4}[A-Z0-9]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[D]{1}[0-9A-Z]{1}/,
  TCS_REGEX = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[C]{1}[0-9A-Z]{1}/,
  ARID_REGEX = /[0-9]{12}[A][R][0-9A-Z]{1}/,
  ALL_REGEXES = [GSTIN_REGEX, UIN_REGEX, NRID_REGEX, OIDAR_REGEX, TDS_REGEX, TCS_REGEX, ARID_REGEX];

export function validateGSTNumber(gstNumber) {
  // Convert to Uppercase for simplifying Regex match
  gstNumber = String(gstNumber).toUpperCase();

  // First perform Regex matching. There are various Regex applicable. Anyone of them can match.
  // If none of the Regex matches, then return false
  let regex_match_found = false;
  for (let r = 0; r < ALL_REGEXES.length; r++) {
    regex_match_found = ALL_REGEXES[r].test(gstNumber);
    if (regex_match_found === true) {
      break;
    }
  }

  if (regex_match_found === false) {
    return false;
  }


  // Regex Matches. Do Checksum Validation
  const CHECKSUM_WEIGHT_CHARACTERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const CHECKSUM_FACTOR_EVEN_PLACE = 1;
  const CHECKSUM_FACTOR_ODD_PLACE = 2;

  let gst_number_last_letter = gstNumber[gstNumber.length - 1];
  let sum = 0;

  let factor = CHECKSUM_FACTOR_EVEN_PLACE;

  let checksum_mod = CHECKSUM_WEIGHT_CHARACTERS.length;
  for (let i = 0; i < gstNumber.length - 1; i++) {
    let current_letter_weight = CHECKSUM_WEIGHT_CHARACTERS.indexOf(gstNumber[i]);

    if (current_letter_weight !== -1) {
      let current_checksum_digit = current_letter_weight * factor;
      current_checksum_digit = parseInt(current_checksum_digit / checksum_mod) + (current_checksum_digit % checksum_mod);
      sum += current_checksum_digit;
    }
    factor = (factor === CHECKSUM_FACTOR_EVEN_PLACE) ? CHECKSUM_FACTOR_ODD_PLACE : CHECKSUM_FACTOR_EVEN_PLACE;
  }

  let calculated_checksum_weight = (checksum_mod - (sum % checksum_mod)) % checksum_mod;
  let calculated_checksum_letter = CHECKSUM_WEIGHT_CHARACTERS[calculated_checksum_weight] ?? '';
  if (calculated_checksum_letter !== gst_number_last_letter) {
    return false;
  }

  return true;
}

/**
 * Check if a GST number is found in the File name, and if found, it should match the input GST number.
 * @param {String} input_file_name 
 * @param {String} input_gst_number 
 * @returns {Boolean}
 */
export function validateGstNumberInFileName(input_file_name, input_gst_number) {
  // Sanitize
  input_file_name = String(input_file_name).trim().toUpperCase();
  input_gst_number = String(input_gst_number).trim().toUpperCase();

  // Ignore any validations if any of them is empty (blank)
  if (input_file_name === '' || input_gst_number === '') {
    return true;
  }

  for (let r = 0; r < ALL_REGEXES.length; r++) {
    const matches = input_file_name.match(new RegExp(ALL_REGEXES[r], "g")); // "g" Adds a global flag to get multiple matches

    // If no match found, move to next Regex
    if (matches === null) {
      continue;
    }

    // We are here, it means Matches are found.

    // Check if the input GST Number found in any of the matches
    if (matches.indexOf(input_gst_number) === -1) {
      return false; // GST number found in the file name, but it does not match Input GST number
    }

    // GST Number found in the file name, and it matches Input GST Number
    return true;
  }

  // No GST Number found at all in the file name
  return true;
}

export function getSuplierContactEmailUrl(currentPage, searchLocation, params) {
  let filterStr = `?company_id=${params.company_id}&return_financial_year=${params.return_financial_year}&year=${params?.return_financial_year ?? params.year}&filter_period=${params.filter_period}`;
  if (params.filter_period_values) {
    filterStr += `&filter_period_values=${params.filter_period_values}`;
  }
  let returnUrl = `${BASE_PATH}${currentPage}${searchLocation}`;
  let encodeURL = btoa(encodeURIComponent(returnUrl));
  // let decodeURL = decodeURIComponent(returnUrl);
  filterStr += `&return_url=${encodeURL}`;
  let finalUrl = `${BASE_PATH}supplier-email${filterStr}`;
  return finalUrl;
}

/**
 * @typedef {Object} ReturnPeriod
 * @property {Integer} year Return year
 * @property {Integer} month Return month
 */


/**
 * Function to give an array of Return Periods between two Input return periods (rp_1 and rp_2).
 * A return period is an object of two keys: year (int) and month (int).
 * This function includes the boundary periods (rp_1 and rp_2)
 * @note This function does not require rp_1 to be smaller than rp_2 or vice versa. It auto-handles the order.
 * @param {ReturnPeriod} rp_1 
 * @param {ReturnPeriod} rp_2 
 * @param {Boolean} descending Defaults to false. Set to true, to get a descending order of Return periods.
 * @returns {Array.<ReturnPeriod>}
 */
export function getReturnPeriodsBetweenGivenPeriodsInclusive(rp_1, rp_2, descending = false) {
  const rp_1_yr = parseInt(rp_1.year);
  const rp_1_mth = parseInt(rp_1.month);

  const rp_2_yr = parseInt(rp_2.year);
  const rp_2_mth = parseInt(rp_2.month);

  // Figure out Start Period and End Period
  let start_yr = rp_2_yr;
  let start_mth = rp_2_mth;
  let end_yr = rp_1_yr;
  let end_mth = rp_1_mth;
  if (rp_1_yr < rp_2_yr || (rp_1_yr === rp_2_yr && rp_1_mth <= rp_2_mth)) {
    start_yr = rp_1_yr;
    start_mth = rp_1_mth;
    end_yr = rp_2_yr;
    end_mth = rp_2_mth;
  }

  if (end_yr < start_yr || (end_yr === start_yr && end_mth < start_mth)) {
    return [];
  }

  let out = [];
  for (let y = start_yr; y <= end_yr; y++) {
    for (let m = 1; m <= 12; m++) {
      if (y === start_yr && m < start_mth) continue;
      if (y === end_yr && m > end_mth) continue;

      // Before July 2017, Return period does not exist
      if (y < 2017 || (y === 2017 && m < 7)) continue;

      out.push({ year: y, month: m });
    }
  }
  return descending ? out.reverse() : out;
}

export function getFYFromDate(date) {
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return getFyFromYearAndMonth(year, month)
}

export function getFirstDateOfYearAndMonth(year, month) {
  let FirstDateOfYearMonth = String(year) + '-' + String(month).padStart(2, '0') + '- 01';
  return FirstDateOfYearMonth;
}

export function getFyFromYearAndMonth(year, month) {
  return parseInt(month) <= LAST_MONTH_FY ? parseInt(year) - 1 : parseInt(year);
}

export function getYearFromFYandMonth(FY, MONTH) {
  return (parseInt(MONTH) <= LAST_MONTH_FY ? parseInt(FY) + 1 : parseInt(FY));
}

export const FIRST_MONTH_FY = 4; // APRIL
export const LAST_MONTH_FY = 3; // MARCH
/**
 * Get First Return Period of a given Financial Year.
 * @param {Integer} FY 
 * @returns {ReturnPeriod}
 */
export function getFirstReturnPeriodofFY(FY) {
  return {
    year: getYearFromFYandMonth(FY, FIRST_MONTH_FY),
    month: FIRST_MONTH_FY
  };
}

/**
 * Get Last Return Period of a given Financial Year.
 * @param {Integer} FY 
 * @returns {ReturnPeriod}
 */
export function getLastReturnPeriodOfFY(FY) {
  return {
    year: getYearFromFYandMonth(FY, LAST_MONTH_FY),
    month: LAST_MONTH_FY
  };
}

/**
 * Get array of Return Periods from which the Original Record can be amended in the Input Return Period.
 * @param {ReturnPeriod} return_period
 * @param {Boolean} descending Defaults to false. Set to true, to get in descending order
 * @returns {Array.<ReturnPeriod>}
 */
export function getReturnPeriodsForAmendments(return_period, descending = true) {
  const rt_yr = parseInt(return_period.year);
  const rt_mth = parseInt(return_period.month);

  // On or before July 2017, amendment is not possible
  if (rt_yr < 2017 || (rt_yr === 2017 && rt_mth <= 7)) {
    return [];
  }

  // Subtract one period to get the Upper limit of Original Periods for Amendment
  const rp_2 = rt_mth === 1 ? { year: rt_yr - 1, month: 12 } : { year: rt_yr, month: rt_mth - 1 };

  // April to September - Can amend previous Year April onwards
  // January to March - Can amend previous Year April onwards
  if (rt_mth <= 9) {
    return getReturnPeriodsBetweenGivenPeriodsInclusive(
      { year: rt_yr - 1, month: 4 }, rp_2, descending
    );
  }

  // October to Decemeber - Can amend current Year April onwards
  return getReturnPeriodsBetweenGivenPeriodsInclusive(
    { year: rt_yr, month: 4 }, rp_2, descending
  );
}

export function getFinancialYearsForAmendments(selectedYear, selectedMonth, startYear = 2017) {
  const years = [];
  let endYear = selectedMonth <= 4 ? parseInt(selectedYear) - 1 : parseInt(selectedYear);
  while (endYear >= startYear) {
    let next_year = endYear + 1;
    years.push({ label: `${endYear}-${next_year.toString().substr(2, 2)}`, value: `${endYear}` });
    endYear = endYear - 1;
  }
  return years;
}


export function convertYearToFormattedYear(year) {
  if (year === null || year === 0) {
    return '';
  }
  let formatYear = Number(year) + 1;

  return year + '-' + formatYear.toString().substr(-2);
}


export function isValidEmail(emailId) {
  return String(emailId)
    .trim()
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}


// Private method - Use getRunningConnectorUsability from external files
async function getRunningConnectorDetails() {

  for (let i = 0; i < CONNECTOR_LOCAL_PORTS.length; i++) {

    try {
      const result = await getConnectionStatus(CONNECTOR_LOCAL_PORTS[i]);
      if (parseInt(result.status ?? 0) === 1) {
        return {
          port: CONNECTOR_LOCAL_PORTS[i],
          version: parseInt((JSON.parse(atob(result.data))).version ?? 0)
        };
      }
    } catch (error) {
    }

  }

  return { port: 0, version: 0 };
}

export async function getRunningConnectorUsability(latestConnectorVersion) {
  if (latestConnectorVersion <= 0) {
    return {
      port: 0,
      install: 1,
      update: 0,
    };
  }

  // Get Running Connector Details
  const runningConnectorDetails = await getRunningConnectorDetails();

  // No connector Running. It is mandatory to download Connector installer
  if (runningConnectorDetails.port === 0) {
    return {
      port: 0,
      install: 1,
      update: 0,
    };
  }

  // If Running version is less than the Current Version, then we need to Download Installer
  if (runningConnectorDetails.version < latestConnectorVersion) {
    return {
      port: runningConnectorDetails.port,
      install: 1,
      update: 0,
    };
  }

  // Stable Version concept is not supported now
  /*
  // Running version >= Stable version
  // However, if it is less than the Latest version, then we can proceed. However, user can update also (if needed)
  if (runningConnectorDetails.version < cv) {
    return {
      port: runningConnectorDetails.port,
      install: 0,
      update: 1,
    };
  }
  */

  // Else, all good.
  return {
    port: runningConnectorDetails.port,
    install: 0,
    update: 0,
  };

}

/**
 * Checks if GSTR 2B is applicable or not.
 * @param {Integer} year 
 * @param {Integer} month 
 * @returns {Boolean}
 */
export function isGstr2BApplicable(year, month) {
  // Applicable from July 2020 onwards.
  return parseInt(year) > 2020 || (parseInt(year) === 2020 && parseInt(month) >= 7);
}

/**
 * Checks if Table 3.1.1 (GSTR 3B - Ecommerce Outward Supplies) is applicable or not.
 * @param {Integer} year 
 * @param {Integer} month 
 * @returns {Boolean}
 */
export function isGstr3BTable3_1_1Applicable(year, month) {
  // Applicable from July 2022 onwards.
  return parseInt(year) > 2022 || (parseInt(year) === 2022 && parseInt(month) >= 7);
}

// Eligible ITC V2 changes from August 2022 onwards
export function isEligibleITCV2Applicable(year, month) {
  year = parseInt(year);
  month = parseInt(month);
  return (year > 2022 || (year === 2022 && month >= 8));
}

export function getEligibleITCModeForFY(financial_year) {
  const fy = parseInt(financial_year);
  // Transition Period - FY 2022
  if (fy === 2022) return 'v1_v2';
  // V1 Period - Before FY 2022
  if (fy < 2022) return 'v1';
  // V2 Period - After FY 2022
  return 'v2';
}

export function getEligibleITCModeConsiderTransition(year, month, consolidated_from_start) {
  year = parseInt(year);
  month = parseInt(month);

  // If we need to consider Complete FY (multi-month) scenario
  if (consolidated_from_start) {
    const fy = getFyFromYearAndMonth(year, month);

    if (fy < 2022) return 'v1';
    if (fy > 2022) return 'v2';

    // FY = 2022

    // April to July - v1
    if (month >= 4 && month <= 7) return 'v1';
    return 'v1_v2';

  }

  // Transition Period - August 2022
  if (year === 2022 && month === 8) return 'v1_v2';
  // V1 Period - Before August 2022
  if (year < 2022 || (year === 2022 && month < 8)) return 'v1';
  // V2 Period - After August 2022
  return 'v2';

}
export function formatBookR1DiffAmount(book_taxable, gstr1_taxable, diff_taxable) {
  let defaultString = '';
  if (Number(book_taxable) > Number(gstr1_taxable)) {
    defaultString = 'excess declared in Books';
  } else if (Number(gstr1_taxable) > Number(book_taxable)) {
    defaultString = 'excess declared in GSTR 1';
  }
  let n = (Math.round(diff_taxable * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString('en-IN');
  // eslint-disable-next-line eqeqeq
  if (n == parseInt(n)) ls = ls + ".00";
  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) { ls = ls + "0"; }

  let value = '';
  let color = '';

  if (Number(diff_taxable) > 0) {
    value = `${ls} ${defaultString}`;
    color = '#28a745';
  } else if (Number(diff_taxable) < 0) {
    ls = ls.substring(1);
    value = `${ls} ${defaultString}`;
    color = '#ff116c';
  } else {
    value = ls;
  }
  return {
    'value': value,
    'color': color,
  };
}
export function formatNegativeNumber(value, flag = false) {
  if (value < 0) {
    if (flag) {
      return <span>({formatNumber(Math.abs(value))})</span>
    }
    return formatNumber(value)
  }
  return formatNumber(value)
}

export const FormatNumberIntValue = (val, maxDigit = 14, negative = false) => {
  let value = String(val).replace(/[^\d.-]/g, "");
  value = value.substring(0, maxDigit);
  if (isNaN(value)) return "";
  return Number(value);
};

export const FormatNumberDecValue = (val, maxDigit = 14, decimal = 2, negative = false, decimalAllow = true) => {
  if (val === null || val.length === 0) {
    return "";
  }
  if (val === "-" && negative === false) {
    return "";
  }

  if (/^[^0-9-.]*$/.test(val)) {
    return "";
  }


  val = String(val).replace(/-+/g, '-');
  val = val[0] + val.substr(1,).replace(/[^0-9. ]/g, '')
  let value = String(val);
  if (negative === true && String(val[0]) === "-") {
    value = value.slice(1);
    if (value.length === 0) {
      return "-";
    }
  }

  let tmp = String(value).split(".");
  let newValue = 0;
  if (tmp.length === 1 || !decimalAllow) {
    if (!negative) {
      value = value.replace(/[^\d]/g, "");
    }
    newValue = Number(FormatNumberIntValue(value, maxDigit));
  } else if (tmp.length === 2) {
    let value1 = FormatNumberIntValue(tmp[0], maxDigit);
    // Remove negative values while allowing decimal values, if the negative flag is set to false.
    if (String(value1)?.[0] === "-" && negative === false) {
      value1 = value1.slice(0, maxDigit)
    }

    let value2 = tmp[1];
    newValue = value1 + "." + String(value2).slice(0, decimal);
    if (Math.pow(10, maxDigit) <= Number(value1) + 1) {
      newValue = value1;
    }
  }



  return newValue;
};


// Eligible ITC Other changes from 2023 onwards
export function isCNApplicableInEligibleItc4B2(year, month) {
  year = parseInt(year);
  month = parseInt(month);
  return (year < 2023);
}


export function IsEmail(email) {
  const regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(email)) {
    return false;
  }
  return true;
}
export function IsPhone(phone) {
  const regex = /^[6-9]\d{9}$/;
  if (!regex.test(phone)) {
    return false;
  }
  return true;
}
export function IsValidPassword(password) {
  const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
  return regex.test(password);
}
export function IsOtp(otp) {
  const regex = /^\d{6}$/;
  return regex.test(otp);
}

export function tallySyncUpdateLocalStorage(mobileNo, gstNo, response) {
  if (!gstNo) return
  let localData = JSON.parse(localStorage.getItem("tally-sync-progress-" + mobileNo))
  if (!localData) localData = {}
  if (response?.process_status === "IN_PROGRESS") {
    localData[gstNo] = response?.process_id
  } else if (response?.process_status === "COMPLETE" || response?.process_status === "FAILURE") {
    delete localData[gstNo]
  }
  localStorage.setItem("tally-sync-progress-" + mobileNo, JSON.stringify(localData))
}

export function tallyListAfterAddInLocal(tallyList, gstin_num) {
  let localData = JSON.parse(localStorage.getItem("tally_companies"))
  if (!localData) localData = {}
  const currentTimeStamp = new Date().getTime()

  // Loop over all the companies in local storage and mark them is_running = false. Also, delete older entries
  for (const [key, ObjValue] of Object.entries(localData)) {
    const lastTallyAddTime = parseInt(ObjValue?.lastViewedAt ?? 0)
    const dayDiff = (currentTimeStamp - lastTallyAddTime) / (86400000)
    if (dayDiff >= TALLY_DELETE_DAYS) {
      delete localData[key]
    } else {
      localData[key]["is_running"] = false
    }
  }

  // Update current running company. It will also automatiically update the is_running flag to true
  tallyList.forEach((item) => {
    delete localData[item.tally_gstin]
    delete localData[item.tally_guid] // To remove previously saved data in the local storage on the user's system based on a unique key, so that duplicate rows are not created
    if (!item.mapped_company_id && item.tally_gstin && item.tally_gstin !== gstin_num) item.is_running = false; // disable row
    localData[item.tally_gstin] = { ...item, lastViewedAt: currentTimeStamp }
  })
  localStorage.setItem("tally_companies", JSON.stringify(localData))
  const data = []
  Object.values(localData).forEach(item => data.push(item));
  return data
}

export function encryptPasswordString(password) {
  var CryptoJS = require("crypto-js");
  let key = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPTION_KEY);
  let iv = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPTION_IV);
  var encryptedPassword = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7
  }).toString();

  return encryptedPassword;
}

export const deepClone = (data) => {
  return JSON.parse(JSON.stringify(data));
};


export const getMonthYearFilterList = (startMonth = 9, startYear = 2017) => {

  const AYs = [];
  const currentYear = parseInt(moment().format('YYYY'), 10);
  const currentMonth = parseInt(moment().format('MM'), 10);

  while (!(startYear === currentYear && startMonth > currentMonth)) {
    const formattedMonth = startMonth < 10 ? `0${startMonth}` : startMonth; // Pad single digit months with leading zero
    AYs.push({ label: `${moment(`${startYear}-${formattedMonth}`, 'YYYY-MM').format('MMM YYYY')}`, value: `${startYear}-${formattedMonth}` });
    if (startMonth === 12) {
      startMonth = 1;
      startYear += 1;
    } else {
      startMonth += 1;
    }
  }

  return AYs;
}
export function convertNum(value) {
  if (isNaN(value)) return 0;
  return Number(value);
}


//Global Encoder
export const encodeParams = (params) => {
  const jsonString = JSON.stringify(params);
  const base64String = btoa(jsonString);
  return base64String;
};


//Global Decode
export const decodeBase64 = (encodedString) => {
  try {
    // Decode the Base64 encoded string
    const decodedString = atob(encodedString);
    return decodedString;
  } catch (e) {
    // Handle error if the input string is not valid Base64
    return 'Invalid Base64 string';
  }
}

// Utility function to encode parameters
export const encodeParamsHeaderMulti = (selectedYears, checkMonths) => {
  const params = {
    year: selectedYears,
    month: checkMonths.map(m => m.text)
  };
  const jsonString = JSON.stringify(params);
  return btoa(jsonString); // Base64 encode the JSON string directly
};

// Utility function to decode parameters
export const decodeParamsHeaderMulti = (encodedString) => {
  try {

    if (!encodedString) { // handle null or undefined value
      let selectedYears = [];
      const current_year = getCurrentFinancialYear();
      selectedYears.push(current_year.id);
      return { selectedYears: [], selectedMonths: [] };
    }

    const decodedString = atob(encodedString); // Base64 decode the string
    const params = JSON.parse(decodedString);
    let selectedYears = params.year || [];

    if (selectedYears.length === 0) {
      const current_year = getCurrentFinancialYear();
      selectedYears.push(current_year.id);
    }

    const selectedMonths = params.month ? params.month.map(text => ({ text })) : [];
    return { selectedYears, selectedMonths };
  } catch (e) {
    console.error("Error decoding parameters:", e);
    return { selectedYears: [], selectedMonths: [] };
  }
};

// Eligible ITC V2 changes from August 2023 onwards
export function isEligibleITCReclaimedTableApplicable(year, month) {
  year = parseInt(year);
  month = parseInt(month);
  return (year > 2023 || (year === 2023 && month >= 8));
}

//Utility function to transform data
export const transformData = (years, months) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  if (months.length === 0) {
    return years.flatMap(year => (
      Array.from({ length: 12 }, (_, i) => {
        const adjustedMonth = (i + 3) % 12 + 1;  // April is 4th month, so start from 3
        const adjustedYear = adjustedMonth >= 4 ? parseInt(year) : parseInt(year) + 1;
        // Filter out months beyond the current month and year
        if (adjustedYear > currentYear || (adjustedYear === currentYear && adjustedMonth > currentMonth)) {
          return null;
        }
        return { year: adjustedYear, month: adjustedMonth };
      }).filter(item => item !== null)
    ));
  } else {
    const monthNameToNumber = {
      Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
      Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
    };

    const monthsData = months.map(month => {
      const [monthStr, yearStr] = month.text.split('-');
      const monthNumber = monthNameToNumber[monthStr];
      const year = parseInt(yearStr, 10) + 2000;
      return { year, month: monthNumber };
    });

    const combinedData = years.flatMap(year => {
      return monthsData
        .filter(monthData => {
          //eslint-disable-next-line
          const adjustedYear = monthData.month >= 4 ? parseInt(year) : parseInt(year) + 1;
          const withinYearBounds = (monthData.year === parseInt(year) && monthData.month >= 4) ||
            (monthData.year === parseInt(year) + 1 && monthData.month < 4);
          const notInFuture = monthData.year < currentYear ||
            (monthData.year === currentYear && monthData.month <= currentMonth);
          return withinYearBounds && notInFuture;
        })
        .map(monthData => ({
          year: monthData.year,
          month: monthData.month
        }));
    });

    return combinedData;
  }
};

export const getPeriodString = (month, year) => {
  if (month === null || year === null) return ('-')
  let month_name = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <>
      {month_name[month - 1]}-{String(year ?? '').substr(2, 2)}
    </>
  )
}
export function getCurrentFinancialYearForDropdown() {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  if (currentMonth > 3) return parseInt(currentYear, 10);

  return parseInt(currentYear, 10) - 1;
}

export function getFinancialYearsForDropdown(minFY = 2023, descending = true) {
  const FYs = [];
  const maxFY = parseInt(getCurrentFinancialYearForDropdown());

  if (descending) {
    let fy = maxFY;
    while (fy >= minFY) {
      FYs.push({ label: `${fy}-${String(fy + 1).substr(2, 2)}`, value: fy });
      fy -= 1;
    }
  } else {
    let fy = parseInt(minFY);
    while (fy <= maxFY) {
      FYs.push({ label: `${fy}-${String(fy + 1).substr(2, 2)}`, value: fy });
      fy += 1;
    }
  }

  return FYs;
}


export function getFinancialYearsListForm3CD(year = null) {
  const startYear = year ? year : 2020;
  const years = [];
  let endYear = parseInt(getCurrentFinancialYearForm3CD());
  while (endYear >= startYear) {
    let next_year = endYear + 1;
    years.push({ text: `${endYear}-${next_year.toString().substr(2, 2)}`, value: `${endYear}` });
    endYear = endYear - 1;
  }
  return years;
}

export function getCurrentFinancialYearForm3CD() {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  if (currentMonth > 3) return parseInt(currentYear, 10);

  return parseInt(currentYear, 10) - 1;
}

export function getStateLabel(code) {
  let val = ""
  if (STATE_UT_LIST?.length > 0) {
    STATE_UT_LIST.forEach((item) => {
      if (item?.value === code) {
        val = item?.label.substring(3,)
      }
    })
    return val
  }
};
export const getDocumentTypeLable = (type) => {
  switch (type) {
    case 'C':
    case 'CN': return 'Credit Note';
    case 'D':
    case 'DN': return 'Debit Note';
    case 'Impg': return 'IMPG';
    case 'Impgsez': return 'IMPGSEZ';
    case 'invoice': return 'Invoice';
    default: return type;
  }
}

//Throttling to limit number of button register calls inorder to avoid unessary api calls
export function throttle(func, delay) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
}


// Debounce function to limit number of calls on each input value change
export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const countRateWiseMultiLinkedDocuemnt = (rate, data) => {
  let count = 0;
  if (data) {
    data.forEach((item) => {
      if (item.items && item.items.length > 0) {
        const rateWiseSubItems = item.items.filter(v => ((rate === null && rate === v.rate) || Number(rate) === Number(v.rate)))
        if (rateWiseSubItems.length > 0) {
          count++;
        }
      }
    })
  }

  return count;

}


export function deepEqual(obj1, obj2) {
  // Check if both values are the same primitive type or are the same reference
  if (obj1 === obj2) return true;

  // Check if either is null or not an object
  if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  // Check if they have the same number of keys
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  // Check if all keys and their values are the same
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

//negative liability condition for the negative inputs
export function isNegativeLiabilityApplicable(year, month) {
  year = parseInt(year);
  month = parseInt(month);

  return (year > 2024 || (year === 2024 && month >= 8))
}

export function createReturnPeriods(type = "", year = "", selectedVal = []) {
  let return_period = [];
  const monthList = getFinancialMonths(year);
  // console.log("monthlist", monthList)
  if (type === "year") {
    monthList.forEach((item) => return_period.push({ year: parseInt(item.year), month: item.value }));
  } else if (type === "month") {
    monthList.forEach((item) => {
      if (selectedVal.includes(item.id)) return_period.push({ year: parseInt(item.year), month: item.value });
    });
  }else if(type === "quarter"){
    const qrtrMth = { Q1: [4, 5, 6], Q2: [7, 8, 9], Q3: [10, 11, 12], Q4: [1, 2, 3,]};
    const selMthVal = selectedVal.reduce((accumulator, currentValue) => [...accumulator, ...qrtrMth[currentValue]], [],);
    monthList.forEach((item) => {
      if(selMthVal.includes(item.value)) return_period.push({ year: parseInt(item.year), month: item.value });
    })

  }
  return return_period;
}
   
//negative liability condition for showing data
export function isNegativeLiabilityDataApplicable(year, month) {
  year = parseInt(year);
  month = parseInt(month);

  return (year > 2024 || (year === 2024 && month >= 9))
}

export function getStateName(StateNo) {
  let stateName = '-';
  PLACE_OF_SUPPLY_LIST.forEach((item) => {
    if (Number(StateNo) === Number(item?.value)) {
      stateName = item.label
    }
  })
  if (stateName !== '-') {
    return stateName.split('-')?.[1];
  }
  return stateName;
}

export function convertTo12HourFormat(dateTime) {
  // Parse the date-time string into a Date object
  const [date, time] = dateTime.split(' ');
  const [day, month, year] = date.split('/');
  const [hours, minutes, seconds] = time.split(':');

  const dateObj = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);

  // Extract components for formatting
  let hour = dateObj.getHours();
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
  const formattedHour = String(hour).padStart(2, '0');
  const formattedMinutes = String(dateObj.getMinutes()).padStart(2, '0');
  
  // Construct the formatted time string
  return `${day}/${month}/${year} ${formattedHour}:${formattedMinutes} ${ampm}`;
}